@media only screen and (max-width: 920px) {
    app-custom-opefficiency-third {
        padding: 0 2%;
    }

    .AfluriasPageTemplate #influenza-burden-hero .container {
        margin-left: 50px !important;
    }
}

@media (min-width: 768px) {
    .AfluriasPageTemplate #influenza-burden-hero .container .line {
      max-width: 310px;
    }
}

@media(max-width:1024px) {
    .AfluriasPageTemplate #influenza-burden-hero {
      height: 100% !important;
    }
}

@media (min-width: 1024px) {
    .AfluriasPageTemplate app-alfuria-third {
        .prod-tabs__tab .prod-tabs__body p {
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
            color: #5d6f7b;
            strong {
                font-size: 24px;
                color: #2A3237;
            }
        }
        .prod-tabs__tab .prod-tabs__body .afluria-sub1 {
            margin-top: 10px;
            strong {
                font-size: 16px;
                line-height: 24px;
                font-family: 'CamptonSemiBold';
                color: #5d6f7b;
            }
        }
        .prod-tabs__tab .prod-tabs__body .afluria-sub2 {
            margin-top: 0;
        }
    }
    .AfluriasPageTemplate app-access-resources {
        #home_resourcessection {
            .global_greybtncontainer {
                .global_greybtn {
                    font-family: "Campton-SemiBold", sans-serif;
                    border: 1px solid #5D6F7B;
                    padding: 17px 29px 17px 29px;
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    .AfluriasPageTemplate app-custom-opefficiency-third {
        padding: 0 4%;

        .global_dashheader {
            font-size: 24px;
            padding-bottom: 20px;
            position: relative;
            right: 50px;
            bottom: 23px;
        }
        .global_line {
            bottom: 27px;
            position: relative;
        }
    }

    .AfluriasPageTemplate #influenza-burden-hero {
      background-image: url('/assets/images/cells-bg.png');
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: calc(130%);
    }

    .AfluriasPageTemplate #influenza-burden-hero {
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0;

        .container {
            max-width: 415px;
            left: 3%;
            bottom: 40%;
            padding-left: 0;

            .line {
                font-size: 32px;
                line-height: 40px;
                max-width: 460px;
                padding-left: 0 !important;
                width: 110%;
                margin-bottom: 28px;
            }
        }
    }
  }

  .AfluriasPageTemplate #influenza-burden-hero {
    margin-bottom: 40px !important;
    height: 229px !important;
    margin-left: 0 !important;
    overflow: hidden !important;
  }

  .AfluriasPageTemplate #influenza-burden-hero .line {
    margin-top: 0;
    padding-left: 22px !important;
    margin-bottom: 30px;
  }
  
  .AfluriasPageTemplate #influenza-burden-hero a {
    font-size: 14px !important;
    padding-left: 23px !important;
    @media (min-width: 1024px) {
        padding-left: 0 !important;
    }
  }

  .AfluriasPageTemplate {
    .prod-sbs__key-features-list {
      color: #5d6f7b;
    }

    .hero--prod__link-blue {
        color: #ffffff !important;
        background-color: #4ECFF8 !important;
        border: 1px solid #4ECFF8;
    }
    
    .hero--prod__link {
        display: inline-block;
        font-family: "Campton-SemiBold", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        text-align: center;
        line-height: 24px;
        padding: 15px 30px;
    }
}

.AfluriasPageTemplate app-custom-opefficiency-third {
    padding: 70px;
 
    .global_dashheader {
        color: #2A3237;
        font-family: campton-medium;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        padding: 0;
    }
    
}

body.loggedin .AfluriasPageTemplate {
    @media (min-width: 1025px) {
        /* app-alert-banner {
            #alertlogin {
                padding: 20px 50px;
            }
        } */
        /* app-alfuria-first {
            position: relative;
            transform: translateY(-5%);
        } */
        .hero--prod__info .dynamic-content-wrapper .hero--prod__approval {
            width: 110%;
        }
        #afluria {
            .hero--prod__logo {
                img {
                    top: 26%;
                }
            }
            .hero--prod__footer .container .hero--prod__cpt-content--alt {
                .hero--prod__footer-header {
                    font-size: 16px;
                    padding-left: 2%;
                }
                .hero--prod__cpt-code {
                    font-size: 16px;
                    width: 100%;
                }
            }
        }
    }
    // .global_blackarrow{
    //     margin: 0% 152px !important;
    // }
}

.AfluriasPageTemplate {
    .vaccine-portfolio-row {
        display: none;
    }

    @media (min-width: 1025px) {
        .afluria > .vaccine-portfolio-row {
            display: flex;
            align-items: flex-end;
        }

        p {
            font-size: 18px;
            line-height: 27px;
            margin: 0 0 22px;
        }

        .afluria-card {
            flex: 0 0 50%;
            border-bottom: none !important;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        .afluria-card:first-child {
            border-right: 1px solid rgba(174, 183, 189, 0.5);
            padding-right: 3%;
        }

        .afluria-card:last-child {
            padding-left: 2%;
        }

        .no-underline {
            font-size: 18px;
            line-height: 27px;
        }

    }   

    .afluria-card {
        text-align: center;
        border-bottom: 1px solid rgba(174, 183, 189, 0.5);
        padding-top: 40px;
        padding-bottom: 40px;

        > img {
            margin-bottom: 40px;
            border: 0;
        }
    }

    .no-underline {
        font-family: 'CamptonSemiBold';
        margin-left: 0px;
        color: #2A3237;
        font-size: 16px;

        .arrow {
            margin-left: 8px;
        }
    }
}

body.loggedin .AfluriasPageTemplate{
    .prod-sb__img {
        max-width: 250px !important;
    }
    @media (min-width: 1025px) {
        .prod-sb__left {
                flex: 0 0 40%;
                justify-content: flex-start;
                padding-left: 80px;
        }
    }
    .home_desktopresourcessection{
        display: flex !important;
    }
    .prod-tabs__tab.active .prod-tabs__body {
        min-height: 255px;
            
    }
}
@media(min-width: 1900px) {
.container {
    width: 100% !important;
    max-width: 1650px !important;
}
.RealWorldEvidencePageTemplate .product_realworld-whitebox {
    position: relative;
    width: 100%;
    right: 8px;
    top: -51px;
}
// .RealWorldEvidencePageTemplate h1.product_efficacy-header{left: 50%;}
.RealWorldEvidencePageTemplate .egg-icon {
    bottom: 250px !important;
}
body.loggedin .RealWorldEvidencePageTemplate .egg-icon {
    position: absolute;
    bottom: 78% !important;
    left: 297px;
}
.GigyaRegistrationPageTemplate .BodyContent .createprofile_helptext {
    padding-right: 5%;
    margin-bottom: 0px;
}
.FlucelvaxPageTemplate .bottom_footer{width:100% !important;}
.RealWorldEvidencePageTemplate img.js-responsive-image.young-girl{left: 22% !important;}


}

.OperationalEfficiencyNewPageTemplate .global_dashheader{font-size: 24px !important;}
/*************Global Custom Fonts *************/
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url(https://fonts.googleapis.com/css?family=Dosis:400,300,500,600);
@import 'ngx-toastr/toastr';
@font-face{
    font-family:Campton-Book;
    src: url("/assets/fonts/campton/Campton-Book.ttf") format("ttf"),
      url("/assets/fonts/campton/Campton-Book.otf") format("otf"),
      url("/assets/fonts/campton/Campton-Book.woff") format("woff");

  }
  @font-face{
    font-family:Campton-Bold;
    font-style:normal;
    src:url(/assets/fonts/campton/Campton-Bold.ttf) format("ttf");
    src:url(/assets/fonts/campton/Campton-Bold.woff) format("woff")
  }
  @font-face{
    font-family:CamptonBook;
    src:url(/assets/fonts/campton/CamptonBook.woff) format("woff")
  }

  @font-face{
    font-family:Campton-BookItalic;
    src:url(/assets/fonts/campton/Campton-BookItalic.otf) format("opentype")
  }

  @font-face{
    font-family:Campton-ExtraBold;
    src:url(/assets/fonts/campton/Campton-ExtraBold.otf) format("opentype")
  }
  @font-face{
    font-family:Campton-ExtraBoldItalic;
    src:url(/assets/fonts/campton/Campton-ExtraBoldItalic.otf) format("opentype")
  }
  @font-face{
    font-family:Campton-ExtraLight;
    src:url(/assets/fonts/campton/Campton-ExtraLight.otf) format("opentype")
  }

  @font-face{
    font-family:CamptonLight;
    src:url(/assets/fonts/campton/CamptonLight.woff) format("woff")
  }

  @font-face{
    font-family:Campton-ExtraLightItalic;
    src:url(/assets/fonts/campton/Campton-ExtraLightItalic.otf) format("opentype")
  }

  @font-face{
    font-family:Campton-Light;
    src:url(/assets/fonts/campton/Campton-Light.otf) format("opentype"),
    url(/assets/fonts/campton/Campton-Light.ttf) format("ttf")
  }
  @font-face{
    font-family:Campton-LightItalic;
    src:url(/assets/fonts/campton/Campton-LightItalic.otf) format("opentype")
  }


  @font-face{
    font-family:CamptonnMedium;
    src:url(/assets/fonts/campton/CamptonnMedium.woff) format("woff")
  }
  @font-face{
    font-family:Campton-Medium;
    src:url(/assets/fonts/campton/Campton-Medium.ttf) format("ttf"),
    url(/assets/fonts/campton/Campton-Medium.woff) format("woff");
  }

  @font-face{
    font-family:Campton-MediumItalic;
    src:url(/assets/fonts/campton/Campton-MediumItalic.otf) format("opentype")
  }

  @font-face{
    font-family:CamptonLightItalic;
    src:url(/assets/fonts/campton/CamptonLightItalic.woff) format("woff")
  }

  @font-face{
    font-family:Campton-SemiBold;
    src:url(/assets/fonts/campton/Campton-SemiBold.ttf) format("ttf"),
        url(/assets/fonts/campton/Campton-SemiBold.woff) format("woff");
  }
  @font-face{
    font-family:CamptonSemiBold;
    src:url(/assets/fonts/campton/Campton-SemiBold.ttf) format("ttf"),
        url(/assets/fonts/campton/Campton-SemiBold.woff) format("woff");
  }


  @font-face{
    font-family:Campton-SemiBoldItalic;
    src:url(/assets/fonts/campton/Campton-SemiBoldItalic.otf) format("opentype")
  }
  @font-face{
    font-family:Campton-Thin;
    src:url(/assets/fonts/campton/Campton-Thin.otf) format("opentype")
  }

  @font-face{
    font-family:Campton-ThinItalic;
    src:url(/assets/fonts/campton/Campton-ThinItalic.otf) format("opentype")
  }

  @font-face {
    font-family: 'Glyphicons Halflings';

    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
  }
  .glyphicon {
    position: relative;
    top: 3px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .glyphicon-asterisk:before {
    content: "\002a";
  }
  .glyphicon-plus:before {
    content: "\002b";
  }
  .glyphicon-euro:before,
  .glyphicon-eur:before {
    content: "\20ac";
  }
  .glyphicon-minus:before {
    content: "\2212";
  }
  .glyphicon-cloud:before {
    content: "\2601";
  }
  .glyphicon-envelope:before {
    content: "\2709";
  }
  .glyphicon-pencil:before {
    content: "\270f";
  }
  .glyphicon-glass:before {
    content: "\e001";
  }
  .glyphicon-music:before {
    content: "\e002";
  }
  .glyphicon-search:before {
    content: "\e003";
  }
  .glyphicon-heart:before {
    content: "\e005";
  }
  .glyphicon-star:before {
    content: "\e006";
  }
  .glyphicon-star-empty:before {
    content: "\e007";
  }
  .glyphicon-user:before {
    content: "\e008";
  }
  .glyphicon-film:before {
    content: "\e009";
  }
  .glyphicon-th-large:before {
    content: "\e010";
  }
  .glyphicon-th:before {
    content: "\e011";
  }
  .glyphicon-th-list:before {
    content: "\e012";
  }
  .glyphicon-ok:before {
    content: "\e013";
  }
  .glyphicon-remove:before {
    content: "\e014";
  }
  .glyphicon-zoom-in:before {
    content: "\e015";
  }
  .glyphicon-zoom-out:before {
    content: "\e016";
  }
  .glyphicon-off:before {
    content: "\e017";
  }
  .glyphicon-signal:before {
    content: "\e018";
  }
  .glyphicon-cog:before {
    content: "\e019";
  }
  .glyphicon-trash:before {
    content: "\e020";
  }
  .glyphicon-home:before {
    content: "\e021";
  }
  .glyphicon-file:before {
    content: "\e022";
  }
  .glyphicon-time:before {
    content: "\e023";
  }
  .glyphicon-road:before {
    content: "\e024";
  }
  .glyphicon-download-alt:before {
    content: "\e025";
  }
  .glyphicon-download:before {
    content: "\e026";
  }
  .glyphicon-upload:before {
    content: "\e027";
  }
  .glyphicon-inbox:before {
    content: "\e028";
  }
  .glyphicon-play-circle:before {
    content: "\e029";
  }
  .glyphicon-repeat:before {
    content: "\e030";
  }
  .glyphicon-refresh:before {
    content: "\e031";
  }
  .glyphicon-list-alt:before {
    content: "\e032";
  }
  .glyphicon-lock:before {
    content: "\e033";
  }
  .glyphicon-flag:before {
    content: "\e034";
  }
  .glyphicon-headphones:before {
    content: "\e035";
  }
  .glyphicon-volume-off:before {
    content: "\e036";
  }
  .glyphicon-volume-down:before {
    content: "\e037";
  }
  .glyphicon-volume-up:before {
    content: "\e038";
  }
  .glyphicon-qrcode:before {
    content: "\e039";
  }
  .glyphicon-barcode:before {
    content: "\e040";
  }
  .glyphicon-tag:before {
    content: "\e041";
  }
  .glyphicon-tags:before {
    content: "\e042";
  }
  .glyphicon-book:before {
    content: "\e043";
  }
  .glyphicon-bookmark:before {
    content: "\e044";
  }
  .glyphicon-print:before {
    content: "\e045";
  }
  .glyphicon-camera:before {
    content: "\e046";
  }
  .glyphicon-font:before {
    content: "\e047";
  }
  .glyphicon-bold:before {
    content: "\e048";
  }
  .glyphicon-italic:before {
    content: "\e049";
  }
  .glyphicon-text-height:before {
    content: "\e050";
  }
  .glyphicon-text-width:before {
    content: "\e051";
  }
  .glyphicon-align-left:before {
    content: "\e052";
  }
  .glyphicon-align-center:before {
    content: "\e053";
  }
  .glyphicon-align-right:before {
    content: "\e054";
  }
  .glyphicon-align-justify:before {
    content: "\e055";
  }
  .glyphicon-list:before {
    content: "\e056";
  }
  .glyphicon-indent-left:before {
    content: "\e057";
  }
  .glyphicon-indent-right:before {
    content: "\e058";
  }
  .glyphicon-facetime-video:before {
    content: "\e059";
  }
  .glyphicon-picture:before {
    content: "\e060";
  }
  .glyphicon-map-marker:before {
    content: "\e062";
  }
  .glyphicon-adjust:before {
    content: "\e063";
  }
  .glyphicon-tint:before {
    content: "\e064";
  }
  .glyphicon-edit:before {
    content: "\e065";
  }
  .glyphicon-share:before {
    content: "\e066";
  }
  .glyphicon-check:before {
    content: "\e067";
  }
  .glyphicon-move:before {
    content: "\e068";
  }
  .glyphicon-step-backward:before {
    content: "\e069";
  }
  .glyphicon-fast-backward:before {
    content: "\e070";
  }
  .glyphicon-backward:before {
    content: "\e071";
  }
  .glyphicon-play:before {
    content: "\e072";
  }
  .glyphicon-pause:before {
    content: "\e073";
  }
  .glyphicon-stop:before {
    content: "\e074";
  }
  .glyphicon-forward:before {
    content: "\e075";
  }
  .glyphicon-fast-forward:before {
    content: "\e076";
  }
  .glyphicon-step-forward:before {
    content: "\e077";
  }
  .glyphicon-eject:before {
    content: "\e078";
  }
  .glyphicon-chevron-left:before {
    content: "\e079";
  }
  .glyphicon-chevron-right:before {
    content: "\e080";
  }
  .glyphicon-plus-sign:before {
    content: "\e081";
  }
  .glyphicon-minus-sign:before {
    content: "\e082";
  }
  .glyphicon-remove-sign:before {
    content: "\e083";
  }
  .glyphicon-ok-sign:before {
    content: "\e084";
  }
  .glyphicon-question-sign:before {
    content: "\e085";
  }
  .glyphicon-info-sign:before {
    content: "\e086";
  }
  .glyphicon-screenshot:before {
    content: "\e087";
  }
  .glyphicon-remove-circle:before {
    content: "\e088";
  }
  .glyphicon-ok-circle:before {
    content: "\e089";
  }
  .glyphicon-ban-circle:before {
    content: "\e090";
  }
  .glyphicon-arrow-left:before {
    content: "\e091";
  }
  .glyphicon-arrow-right:before {
    content: "\e092";
  }
  .glyphicon-arrow-up:before {
    content: "\e093";
  }
  .glyphicon-arrow-down:before {
    content: "\e094";
  }
  .glyphicon-share-alt:before {
    content: "\e095";
  }
  .glyphicon-resize-full:before {
    content: "\e096";
  }
  .glyphicon-resize-small:before {
    content: "\e097";
  }
  .glyphicon-exclamation-sign:before {
    content: "\e101";
  }
  .glyphicon-gift:before {
    content: "\e102";
  }
  .glyphicon-leaf:before {
    content: "\e103";
  }
  .glyphicon-fire:before {
    content: "\e104";
  }
  .glyphicon-eye-open:before {
    content: "\e105";
  }
  .glyphicon-eye-close:before {
    content: "\e106";
  }
  .glyphicon-warning-sign:before {
    content: "\e107";
  }
  .glyphicon-plane:before {
    content: "\e108";
  }
  .glyphicon-calendar:before {
    content: "\e109";
  }
  .glyphicon-random:before {
    content: "\e110";
  }
  .glyphicon-comment:before {
    content: "\e111";
  }
  .glyphicon-magnet:before {
    content: "\e112";
  }
  .glyphicon-chevron-up:before {
    content: "\e113";
  }
  .glyphicon-chevron-down:before {
    content: "\e114";
  }
  .glyphicon-retweet:before {
    content: "\e115";
  }
  .glyphicon-shopping-cart:before {
    content: "\e116";
  }
  .glyphicon-folder-close:before {
    content: "\e117";
  }
  .glyphicon-folder-open:before {
    content: "\e118";
  }
  .glyphicon-resize-vertical:before {
    content: "\e119";
  }
  .glyphicon-resize-horizontal:before {
    content: "\e120";
  }
  .glyphicon-hdd:before {
    content: "\e121";
  }
  .glyphicon-bullhorn:before {
    content: "\e122";
  }
  .glyphicon-bell:before {
    content: "\e123";
  }
  .glyphicon-certificate:before {
    content: "\e124";
  }
  .glyphicon-thumbs-up:before {
    content: "\e125";
  }
  .glyphicon-thumbs-down:before {
    content: "\e126";
  }
  .glyphicon-hand-right:before {
    content: "\e127";
  }
  .glyphicon-hand-left:before {
    content: "\e128";
  }
  .glyphicon-hand-up:before {
    content: "\e129";
  }
  .glyphicon-hand-down:before {
    content: "\e130";
  }
  .glyphicon-circle-arrow-right:before {
    content: "\e131";
  }
  .glyphicon-circle-arrow-left:before {
    content: "\e132";
  }
  .glyphicon-circle-arrow-up:before {
    content: "\e133";
  }
  .glyphicon-circle-arrow-down:before {
    content: "\e134";
  }
  .glyphicon-globe:before {
    content: "\e135";
  }
  .glyphicon-wrench:before {
    content: "\e136";
  }
  .glyphicon-tasks:before {
    content: "\e137";
  }
  .glyphicon-filter:before {
    content: "\e138";
  }
  .glyphicon-briefcase:before {
    content: "\e139";
  }
  .glyphicon-fullscreen:before {
    content: "\e140";
  }
  .glyphicon-dashboard:before {
    content: "\e141";
  }
  .glyphicon-paperclip:before {
    content: "\e142";
  }
  .glyphicon-heart-empty:before {
    content: "\e143";
  }
  .glyphicon-link:before {
    content: "\e144";
  }
  .glyphicon-phone:before {
    content: "\e145";
  }
  .glyphicon-pushpin:before {
    content: "\e146";
  }
  .glyphicon-usd:before {
    content: "\e148";
  }
  .glyphicon-gbp:before {
    content: "\e149";
  }
  .glyphicon-sort:before {
    content: "\e150";
  }
  .glyphicon-sort-by-alphabet:before {
    content: "\e151";
  }
  .glyphicon-sort-by-alphabet-alt:before {
    content: "\e152";
  }
  .glyphicon-sort-by-order:before {
    content: "\e153";
  }
  .glyphicon-sort-by-order-alt:before {
    content: "\e154";
  }
  .glyphicon-sort-by-attributes:before {
    content: "\e155";
  }
  .glyphicon-sort-by-attributes-alt:before {
    content: "\e156";
  }
  .glyphicon-unchecked:before {
    content: "\e157";
  }
  .glyphicon-expand:before {
    content: "\e158";
  }
  .glyphicon-collapse-down:before {
    content: "\e159";
  }
  .glyphicon-collapse-up:before {
    content: "\e160";
  }
  .glyphicon-log-in:before {
    content: "\e161";
  }
  .glyphicon-flash:before {
    content: "\e162";
  }
  .glyphicon-log-out:before {
    content: "\e163";
  }
  .glyphicon-new-window:before {
    content: "\e164";
  }
  .glyphicon-record:before {
    content: "\e165";
  }
  .glyphicon-save:before {
    content: "\e166";
  }
  .glyphicon-open:before {
    content: "\e167";
  }
  .glyphicon-saved:before {
    content: "\e168";
  }
  .glyphicon-import:before {
    content: "\e169";
  }
  .glyphicon-export:before {
    content: "\e170";
  }
  .glyphicon-send:before {
    content: "\e171";
  }
  .glyphicon-floppy-disk:before {
    content: "\e172";
  }
  .glyphicon-floppy-saved:before {
    content: "\e173";
  }
  .glyphicon-floppy-remove:before {
    content: "\e174";
  }
  .glyphicon-floppy-save:before {
    content: "\e175";
  }
  .glyphicon-floppy-open:before {
    content: "\e176";
  }
  .glyphicon-credit-card:before {
    content: "\e177";
  }
  .glyphicon-transfer:before {
    content: "\e178";
  }
  .glyphicon-cutlery:before {
    content: "\e179";
  }
  .glyphicon-header:before {
    content: "\e180";
  }
  .glyphicon-compressed:before {
    content: "\e181";
  }
  .glyphicon-earphone:before {
    content: "\e182";
  }
  .glyphicon-phone-alt:before {
    content: "\e183";
  }
  .glyphicon-tower:before {
    content: "\e184";
  }
  .glyphicon-stats:before {
    content: "\e185";
  }
  .glyphicon-sd-video:before {
    content: "\e186";
  }
  .glyphicon-hd-video:before {
    content: "\e187";
  }
  .glyphicon-subtitles:before {
    content: "\e188";
  }
  .glyphicon-sound-stereo:before {
    content: "\e189";
  }
  .glyphicon-sound-dolby:before {
    content: "\e190";
  }
  .glyphicon-sound-5-1:before {
    content: "\e191";
  }
  .glyphicon-sound-6-1:before {
    content: "\e192";
  }
  .glyphicon-sound-7-1:before {
    content: "\e193";
  }
  .glyphicon-copyright-mark:before {
    content: "\e194";
  }
  .glyphicon-registration-mark:before {
    content: "\e195";
  }
  .glyphicon-cloud-download:before {
    content: "\e197";
  }
  .glyphicon-cloud-upload:before {
    content: "\e198";
  }
  .glyphicon-tree-conifer:before {
    content: "\e199";
  }
  .glyphicon-tree-deciduous:before {
    content: "\e200";
  }
  .glyphicon-cd:before {
    content: "\e201";
  }
  .glyphicon-save-file:before {
    content: "\e202";
  }
  .glyphicon-open-file:before {
    content: "\e203";
  }
  .glyphicon-level-up:before {
    content: "\e204";
  }
  .glyphicon-copy:before {
    content: "\e205";
  }
  .glyphicon-paste:before {
    content: "\e206";
  }
  .glyphicon-alert:before {
    content: "\e209";
  }
  .glyphicon-equalizer:before {
    content: "\e210";
  }
  .glyphicon-king:before {
    content: "\e211";
  }
  .glyphicon-queen:before {
    content: "\e212";
  }
  .glyphicon-pawn:before {
    content: "\e213";
  }
  .glyphicon-bishop:before {
    content: "\e214";
  }
  .glyphicon-knight:before {
    content: "\e215";
  }
  .glyphicon-baby-formula:before {
    content: "\e216";
  }
  .glyphicon-tent:before {
    content: "\26fa";
  }
  .glyphicon-blackboard:before {
    content: "\e218";
  }
  .glyphicon-bed:before {
    content: "\e219";
  }
  .glyphicon-apple:before {
    content: "\f8ff";
  }
  .glyphicon-erase:before {
    content: "\e221";
  }
  .glyphicon-hourglass:before {
    content: "\231b";
  }
  .glyphicon-lamp:before {
    content: "\e223";
  }
  .glyphicon-duplicate:before {
    content: "\e224";
  }
  .glyphicon-piggy-bank:before {
    content: "\e225";
  }
  .glyphicon-scissors:before {
    content: "\e226";
  }
  .glyphicon-bitcoin:before {
    content: "\e227";
  }
  .glyphicon-btc:before {
    content: "\e227";
  }
  .glyphicon-xbt:before {
    content: "\e227";
  }
  .glyphicon-yen:before {
    content: "\00a5";
  }
  .glyphicon-jpy:before {
    content: "\00a5";
  }
  .glyphicon-ruble:before {
    content: "\20bd";
  }
  .glyphicon-rub:before {
    content: "\20bd";
  }
  .glyphicon-scale:before {
    content: "\e230";
  }
  .glyphicon-ice-lolly:before {
    content: "\e231";
  }
  .glyphicon-ice-lolly-tasted:before {
    content: "\e232";
  }
  .glyphicon-education:before {
    content: "\e233";
  }
  .glyphicon-option-horizontal:before {
    content: "\e234";
  }
  .glyphicon-option-vertical:before {
    content: "\e235";
  }
  .glyphicon-menu-hamburger:before {
    content: "\e236";
  }
  .glyphicon-modal-window:before {
    content: "\e237";
  }
  .glyphicon-oil:before {
    content: "\e238";
  }
  .glyphicon-grain:before {
    content: "\e239";
  }
  .glyphicon-sunglasses:before {
    content: "\e240";
  }
  .glyphicon-text-size:before {
    content: "\e241";
  }
  .glyphicon-text-color:before {
    content: "\e242";
  }
  .glyphicon-text-background:before {
    content: "\e243";
  }
  .glyphicon-object-align-top:before {
    content: "\e244";
  }
  .glyphicon-object-align-bottom:before {
    content: "\e245";
  }
  .glyphicon-object-align-horizontal:before {
    content: "\e246";
  }
  .glyphicon-object-align-left:before {
    content: "\e247";
  }
  .glyphicon-object-align-vertical:before {
    content: "\e248";
  }
  .glyphicon-object-align-right:before {
    content: "\e249";
  }
  .glyphicon-triangle-right:before {
    content: "\e250";
  }
  .glyphicon-triangle-left:before {
    content: "\e251";
  }
  .glyphicon-triangle-bottom:before {
    content: "\e252";
  }
  .glyphicon-triangle-top:before {
    content: "\e253";
  }
  .glyphicon-console:before {
    content: "\e254";
  }
  .glyphicon-superscript:before {
    content: "\e255";
  }
  .glyphicon-subscript:before {
    content: "\e256";
  }
  .glyphicon-menu-left:before {
    content: "\e257";
  }
  .glyphicon-menu-right:before {
    content: "\e258";
  }
  .glyphicon-menu-down:before {
    content: "\e259";
  }
  .glyphicon-menu-up:before {
    content: "\e260";
  }
//bootstrap 3.3.7 code

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-pull-12 {
    right: 100%;
  }
  .col-xs-pull-11 {
    right: 91.66666667%;
  }
  .col-xs-pull-10 {
    right: 83.33333333%;
  }
  .col-xs-pull-9 {
    right: 75%;
  }
  .col-xs-pull-8 {
    right: 66.66666667%;
  }
  .col-xs-pull-7 {
    right: 58.33333333%;
  }
  .col-xs-pull-6 {
    right: 50%;
  }
  .col-xs-pull-5 {
    right: 41.66666667%;
  }
  .col-xs-pull-4 {
    right: 33.33333333%;
  }
  .col-xs-pull-3 {
    right: 25%;
  }
  .col-xs-pull-2 {
    right: 16.66666667%;
  }
  .col-xs-pull-1 {
    right: 8.33333333%;
  }
  .col-xs-pull-0 {
    right: auto;
  }
  .col-xs-push-12 {
    left: 100%;
  }
  .col-xs-push-11 {
    left: 91.66666667%;
  }
  .col-xs-push-10 {
    left: 83.33333333%;
  }
  .col-xs-push-9 {
    left: 75%;
  }
  .col-xs-push-8 {
    left: 66.66666667%;
  }
  .col-xs-push-7 {
    left: 58.33333333%;
  }
  .col-xs-push-6 {
    left: 50%;
  }
  .col-xs-push-5 {
    left: 41.66666667%;
  }
  .col-xs-push-4 {
    left: 33.33333333%;
  }
  .col-xs-push-3 {
    left: 25%;
  }
  .col-xs-push-2 {
    left: 16.66666667%;
  }
  .col-xs-push-1 {
    left: 8.33333333%;
  }
  .col-xs-push-0 {
    left: auto;
  }
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xs-offset-0 {
    margin-left: 0%;
  }


    /*************Global fonts ends here****************/

    html,body {
        font-family: campton-book !important;
        -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
      }
      body{overflow-x:hidden;}
      /*************Global padding and margin custom class****************/

      .clearboth{
      clear: both;
      }

      .no-margin{margin: 0 !important;}
      .no-margin-left{margin-left: 0 !important;}
      .no-margin-right{margin-right: 0 !important;}
      .no-margin-top{margin-top: 0 !important;}
      .no-margin-bottom{margin-bottom: 0 !important;}

      .no-padding{padding: 0 !important;}
      .no-padding-left{padding-left: 0 !important;}
      .no-padding-right{padding-right: 0 !important;}
      .no-padding-top{padding-top: 0 !important;}
      .no-padding-bottom{padding-bottom: 0 !important;}

      .margin-T10{margin-top:10px !important;}
      .margin-T15{margin-top:15px !important;}
      .margin-T20{margin-top:20px !important;}
      .margin-T25{margin-top:25px !important;}
      .margin-T30{margin-top:30px !important;}
      .margin-B20{margin-bottom:20px !important;}
      .margin-L20{margin-left:20px !important;}
      .margin-R20{margin-right:20px !important;}
      .margin-L10{margin-left: 10px !important;}
      .full-width{width:100%!important;}
      .margin-T30 {margin-top: 30px !important;}
      .margin-B30 {margin-bottom: 30px !important;}
      .margin-T5{    margin-top: 5px !important;}

      .padding-T5 {padding-top: 5px;}
      .padding-L5 {padding-left: 5px;}
      .padding-R5 {padding-right: 5px;}
      .padding-B5 {padding-bottom: 5px;}
      .padding-T10 {padding-top: 10px;}
      .padding-L10 {padding-left: 10px;}
      .padding-R10 {padding-right: 10px;}
      .padding-B10 {padding-bottom: 10px;}
      .padding-T20{padding-top:20px !important;}
      .padding-B20{padding-bottom:20px !important;}
      .padding-L20{padding-left:20px !important;}
      .padding-R20{padding-right:20px !important;}
      .padding-B15{padding-bottom:15% !important;}
      .padding-B30{padding-bottom: 30px !important;}
      .padding-L40 {padding-left: 40px !important;;}

      /* global section */

      .margin--T10 {margin-top: -10px !important;}
      .margin--B10 {margin-bottom: -10px !important;}
      .margin--L10 {margin-left: -10px !important;}
      .margin--R10 {margin-right: -10px !important;}

      .margin--T20 {margin-top: -20px !important;}
      .margin--B20 {margin-bottom: -20px !important;}
      .margin--L20 {margin-left: -20px !important;}
      .margin--R20 {margin-right: -20px !important;}
.TopHeaderNavSlot {
    background-color: #F9FAFA !important;
    color: #000 !important;
    display: block;
    min-height: 64px;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  header{
    background: transparent !important;
    color:#5D6F7B;
    // border-bottom: 1px solid #ececec;
  }

  .for-healthcare-profe {
    height: 24px;
    width: auto;
    color: #5D6F7B;
    font-family: Campton-book;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 20px 0;
    float: left;
    padding-left: 3%;
}
//header .header{max-width: 100% !important; width:100%;}
.TopHeaderNavSlot{width:100% !important;}
.header-nav {
    float: right;

}

.header-nav ul li a {
    color: #5D6F7B;
    font-family: Campton-book;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    text-decoration: none;
}
.header_dropdwn {
    padding: 10px !important;
    top: 55px !important;
    left: 12px !important;
    border: none !important;
    box-shadow: none;
    transform: none !important;
    width:250px !important;
}
.header-nav ul li {
    list-style: none;
    display: inline-block ;
    padding: 20px;
    position: relative;
}
.dropdown-menu li:first-child {
    margin-top: 0px;
}
.header_dropdwn li {
    padding: 0px 0px 10px 0px  !important;
    display:block !important;
}
.header_dropdwn li a {
    color: #5D6F7B !important;
    font-family: 'Campton-SemiBold' !important;
    font-size: 16px ;
}
.dropdown-menu > li > a {
    padding: 3px 0px !important;
}

.header_dropdwn li a {
    color: #5D6F7B !important;
    font-family: 'Campton-SemiBold' !important;
    font-size: 16px ;
}

.header-nav .dropdown-menu li a {
    font-size: 16px !important;
    line-height: 16px !important;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
}

.header-nav ul li a {
    color: #5D6F7B ;
    font-family: Campton-book ;
    font-size: 16px ;
    font-weight: 300 ;
    letter-spacing: 0;
    text-decoration: none;
}
.BottomHeaderSlot{display:none;}

@media only screen and (max-width: 991px){
  .BottomHeaderSlot{display:block;}
}

header .header{padding:0px;}
.headerbar {
    background-color: #fff;
}
header .header > *{flex: auto !important;}
/* .headerbar {
    border-bottom: 1px solid #ececec;

} */
.container-fluid .headerSection {
    margin-top: 0%;
    margin-bottom: 0%;
}
.padding-L40 {
    padding-left: 40px !important;
}
.margin-T10 {
    margin-top: 10px !important;
}
.logoimg0 {
    padding-right: 20px;
    border-right: 1px solid #DBE0E2 !important;
    margin-top: 10px;
    width: 47%;
}
@media screen and (min-width: 1024px) and (max-width: 1400px){
.logoUS .logoimg0 {
    width: 48.9%;
}
}
.logoUS .logoimg0 {
    margin-top: 0px;
    width: auto;
}
.logoimg1 {
    width: 35%;
    margin-top: 17px;
    padding-left: 20px;
}
@media screen and (min-width: 1024px) and (max-width: 1400px){
.logoUS .logoimg1 {
    width: 37.5%;
}
}
.navcolumn {
    float: right;
}
.dropdown, .dropup, .navcolumn {
    position: static !important;
}
.header-nav .dropdown, .header-nav.dropup, .header-nav .navcolumn {
    position: relative !important;
}

.primary-nav {
    margin-bottom: 0;
    padding-right: 2.5%;
    display: flex;
    justify-content: flex-end;
}
// .primary-nav ul:first-child {
//     margin-bottom: 6px !important;
// }
.primary-nav ul:first-child {
  margin-bottom: 6px !important;
  margin-right: 15px;

}
.primary-nav ul {
    justify-content: flex-end;
}
.primary-nav ul {
    display: flex;
    flex-direction: row;
    align-items: start;
    list-style-type: none;
    padding-left: 0px;
    justify-content: flex-end;
    margin-bottom: 6px;
}
.primary-nav ul li {
    padding:50px 31px 38px 25px;
    @media(min-width:992px) and (max-width: 1180px){
      padding: 50px 0px 38px 15px;
    }
}
.primary-nav ul:nth-child(1) .dropdown,.primary-nav ul:nth-child(2) .dropdown{
  padding-right: 7%;
}
.primary-nav ul li a {
    position: relative;
    display: block;
    text-decoration: none;
    font-family: Campton-book;
    color: #2A3237;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    transition: 0.5s;
    text-align: center;
    display: table;
    cursor: pointer;
}
.primary-nav ul li a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -6px;
  background: #EA181B;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
  margin-left: 0px;
}
.primary-nav ul:first-child li.show .dropdown-toggle::after {
  transform: scaleX(1);
  transform-origin: left;
}
.container-bottom-header .primary-nav ul li.show a span{
color:#2A3237 !important;
}
.indicator_arrow {
  margin-left: 50px;
    color: transparent;
    font-size: 18px;
    margin-top: 16px !important;
    position: absolute;
    display: none;
    z-index: 100000;
}

_:-ms-lang(x), .indicator_arrow {
    margin-top: 26px; /* IE EDGE */

}
.primary-nav .fa-angle-up {
    color: #ccc;
    background: #fff;
}
.primary-nav ul ul {
    background: #fff;
    padding: 0;
    text-align: right;
    display: none;
    width: 100%;
    position: absolute;
    top: 185px;
    left: 0px;
    padding-right: 4%;
}
.megamenu{
  position: absolute !important;
  right: 10px !important;
}
.megamenu {
    padding-right: 0px !important;
}
.dropdown_first_level {
    display: inline-block !important;
    vertical-align: top;
    padding: 0px 38px 32px 38px !important;
    margin-top: 39px !important;
}
.primary-nav ul li a {
    position: relative;
    display: block;
    text-decoration: none;
    font-family: Campton-book;
    color: #2A3237 !important;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    transition: 0.5s;
    text-align: center;
    display: table;
    cursor: pointer;
}
.dropdown-menu li a {
    font-size: 20px !important;
    line-height: 16px !important;
    margin-bottom: 10px !important;
    padding-left: 0px !important;
}
.primary-nav .nav-item:nth-child(2) .megamenu .dropdown_first_level {
    padding: 0px 38px 32px 38px !important;
    margin-top: 39px !important;
}
.primary-nav ul li a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}
.primary-nav ul li:hover {
    color:#000;
}
.primary-nav ul .has-megamenu.active {
    color:#000;
}
.primary-nav ul .has-megamenu.active .dropdown-toggle::after {
    transform: scaleX(1);
    transform-origin: left;
}
/*.primary-nav ul li:hover > ul {
    display:block;
}

 .primary-nav ul .has-megamenu:hover > .megamenu {
    display:none;
} */
.dropdown_first_level:hover > .second_level_menu{
    display:block;
}
.nav-link{
    padding:0rem 0rem !important;
}

.second_level_menu li a
{
    float:right;
    padding-top:12px;
}

.dropdown, .dropup, .navcolumn{position:static;}
.second_level_menu{
    position: relative !important;
    top: 0px !important;
    padding: 0px !important;
    margin: 0px -57px;
    width: 196px !important;
    text-align: right !important;
}
.second_level_menu li{padding:10px 0px !important; text-align:right !important;}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{background:none !important;}
.dropdown-menu>li>a{padding:3px 0px !important;}
// .second_level_menu li a{font-size:15px !important;}


.primary-nav { margin-bottom: 0 !important; padding-right: 2.5% !important;}
.primary-nav > li.dropdown.open { position: static !important;}
.primary-nav > li.dropdown.open .dropdown-menu {display:block !important; width: 100% !important;  }
// .dropdown-menu>li { display: block !important; }


.header-nav .dropdown-toggle::after{display:none;}
.dropdown-toggle::after{border:none !important;}

.redborder {
  background-color: #F9FAFA;
  border: 2px solid #EA181B;
    padding: 19px 29px !important;
    margin: 25px 0px 28px 25px !important;
    @media(min-width:992px) and (max-width: 1050px){
      padding: 19px 12px !important;
      margin: 25px 0px 28px 15px !important;
    }
}

.create-account {
    font-size: 16px !important;
    font-family: 'CamptonSemiBold' !important;
    color: #EA181B !important;
}
.redborder:focus, .redborder:hover {
    color: #FFFFFF !important;
    background-color: #EA181B;
}
.redborder:hover a{color:#fff !important;}

.LandingPage2Template{
    max-width: 1920px !important;
    width: 100%;
    padding: 0;
    margin: 0 auto !important;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    .for-healthcare-profe {
      padding-left: 0px;
      padding-top: 0px;
    }
    .headerbar {
      padding-right:0px;
    }
    .headerNav-container {
      padding-right: 0px;
    }
    .healthcare-US img {
      width: 33px;
      margin-bottom: 0px !important;
    }
    .savedcartdotmob {
      left: 26px !important;
      top: 0px !important;
    }
    .container-top-header {
      padding: 0 0% 1% 3% !important;
    }
}
.LandingPage1Template{
  max-width: 1920px !important;
    width: 100%;
    padding: 0;
    margin: 0 auto !important;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
}
sup {
    top: -0.5em;
}
// #home_leftoverviewbtn {
//     font-family: 'Campton-Medium';
//     font-size:14px;
// }

#home_planningcontent {
    color: #ffffff;
    background-color: #5D6F7B;
    // display: flex;
    display: block;
    align-items: center;
}
#home_planningleftcontent {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 2% 3%;
    margin: 1% 0%;
    font-family: campton-medium;
}
#home_planningrighttext {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    width: 50;
    padding: 0% 10%;
    line-height: 24px;
    margin: 0% 0% 3% 0%;
}
#home_planninglearn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 0% 10%;
    line-height: 24px;
    cursor: pointer;
}
.global_whitearrow {
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/whitearrow.png");
    margin: 0% 15px;
    width: 28px;
    position: absolute;
    height: 22px;
    display: inline-block;
}

@media (min-width: 1024px){
.col-md-offset-6 {
    margin-left: 50%;
}
/* .AccountPageTemplate .BottomHeaderSlot,.InvoicePageTemplate .BottomHeaderSlot,.paymentInformationPageTemplate .BottomHeaderSlot, .CartPageTemplate .BottomHeaderSlot, .OperationalEfficiencyNewPageTemplate .BottomHeaderSlot, .ClinicalSupportNewPageTemplate .BottomHeaderSlot,.Influenza65PlusNewPageTemplat .BottomHeaderSlot,.AllProductsPageTemplate .BottomHeaderSlot,.RealWorldEvidencePageTemplate .BottomHeaderSlot,.AboutSeqirusPageTemplate .BottomHeaderSlot, .CreateOrderPageTemplate .BottomHeaderSlot, .FaqsPageTemplate .BottomHeaderSlot,.fluadClinicalDataPageTemplate .BottomHeaderSlot,.adjuvantedFluadPageTemplate .BottomHeaderSlot,.FlucelvaxPageTemplate .BottomHeaderSlot,.CookiePolicyPageTemplate .BottomHeaderSlot,.ContentPage1Template .BottomHeaderSlot,.fluadRealWorldEvidencePageTemplate .BottomHeaderSlot,.PublicErrorPageTemplate .BottomHeaderSlot,.efficacyandSafetyPageTemplate .BottomHeaderSlot, .AfluriasPageTemplate .BottomHeaderSlot, .ErrorPageTemplate .BottomHeaderSlot, .FluadPageTemplate .BottomHeaderSlot,.PrescribingInformationPageTemplate .BottomHeaderSlot,.InfleunzaNewPageTemplate .BottomHeaderSlot,.ErrorPageTemplate .BottomHeaderSlot,.CodingAndBillingNewPageTemplate .BottomHeaderSlot,.ResourcesDetailsPageTemplate .BottomHeaderSlot,.ResourcesLandingNewPageTemplate .BottomHeaderSlot{
  width: 75%;
  justify-content: end;
  } */
}
.global_greyredbtncontainer {
    display: flex;
    justify-content: left;
    margin-top: 4%;
}
.global_redbtn {
    color: #EA181B;
    background-color: #ffffff;
    border: 2px solid #EA181B;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-right: 3%;
}
.global_greybtn {
    color: #5D6F7B;
    background-color: #ffffff;
    border: 2px solid #5D6F7B;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.global_redbtn:focus, .global_redbtn:hover {
    color: #FFFFFF;
    background-color: #EA181B;
}



#home_productssection, #home_resourcessection {
    margin-top: 5%;
    padding-left: 4%;
    padding-right: 4%;
}
.global_headerwithdash {
    display: flex;
    justify-content: left;
}
.global_dashheader {
    color: #2A3237;
    font-family: campton-medium;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    padding: 0;
}
.global_line {
    background-color: rgba(174, 183, 189, 0.5);
    height: 1px;
    margin-top: 16px;
    flex: 1;
    margin-left: 2%;
}
#home_vaccineportfolio {
    margin-top: 4%;
}
#home_solutiontabs, #home_vaccineportfolio, #home_resourcetabs {
    display: flex;
    justify-content: space-evenly;
}
.home_fluad {
    background-color: #F9FAFA;
    border-radius: 2%;
    padding: 3% 2%;
}
.home_vaccineimg {
    background-repeat: no-repeat;
    background-size: contain;
    height: 70px;
    margin-top: 10%;
    margin-bottom: 10%;
    background-position-x: center;
}
.home_vaccineheader {
    text-align: center;
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
}
.global_blackarrow {
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/blackarrow.png');
    margin: 0% 10px;
    width: 28px;
    position: absolute;
    height: 22px;
    display: inline-block;
}
#home_safetyinfo {
    color: #5D6F7B;
    font-family: campton-medium;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 2%;
    text-align: center;
}
.home_vaccineheader span {
    text-align: center;
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
}

#home_resourcessection {
    background-color: #f9fafa;
    padding: 50px 4%;
    margin-top: 3%;
}
.global_headerwithdash {
    display: flex;
    justify-content: left;
}
.global_dashheader {
    color: #2A3237;
    font-family: campton-medium;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    padding: 0;
}
.global_line {
    background-color: rgba(174, 183, 189, 0.5);
    height: 1px;
    margin-top: 16px;
    flex: 1;
    margin-left: 2%;
}
.home_desktopresourcessection {
    display: flex;
}
#home_resourcetabs, .home_desktopresourcessection {
    margin-top: 3%;
}
.home_resources {
    padding: 0;
    background-color: #FFF;
    position: relative;
}
.home_resourceimg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0px;
    height: 150px;
    margin-bottom: 30px;
    background-position-x: center;
}
.home_resourceheader {
    color: #5D6F7B;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
}
.home_resourcesubheader {
    color: #2A3237;
    font-family: campton-medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    margin-top: 10px;
    text-align: left;
}
.home_resourcetext {
    color: #5D6F7B;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    margin-top: 10px;
    margin-bottom: 85px;
    text-align: left;
}
.home_resourcelink {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0%;
    border-top: 0.5px rgba(174, 183, 189, 0.5) solid;
    padding: 20px 15px;
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
}
.home_resourcelink span {
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
}

.global_greybtncontainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.home_resourcelink .global_blackarrow {
    position: relative;
    margin: 0;
}

#home_learnseqirus {
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 0%;
    padding: 10% 4% 10% 4%;
}

.global_createaccsubheader {
    color: #5D6F7B;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    margin-top: 3%;
}
.global_greyredbtncontainer {
    display: flex;
    justify-content: left;
    margin-top: 4%;
}
.dropdown.show{
    display: inline-block !important;
}

// a, a:hover, a:focus, a:active {
//   text-decoration: none !important;
//   color: inherit !important;
//   min-height: 0px !important;
// }


.header  .mob_exp_menu {
  display: none ;

}
.BottomHeaderSlot .mob-logo-wrap{display:none;}
.header .BottomHeaderSlot .mob-logo-wrap{display:block; float:right;}
.mob_exp_menu{
  display: none;
}

a, a:hover, a:focus, a:active {
  text-decoration: none !important;
  color: inherit !important;
   min-height: 0px !important;
}


.Footer{
  cx-paragraph{
      display: none;
  }
}
.footer_container .fotter_txt a{
  font-family: Campton-medium;
  color:#fff;
  font-size:15px;
}
.footer_container .fotter_txt a:hover{
  text-decoration:underline;
}
.footer_container .footer_txt_disclaimer p{
  font-family: Campton-book;
  color:#fff;
  font-size:12px;
  line-height:16px;
}
// Homepage solution section changes
#home_solutionheader {
  color: #2A3237;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: campton-medium;
}

#home_solutionsubheader
{
  color: #5D6F7B;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  margin-top:15px;
  margin-bottom:30px;
}
.global_greybtn:focus,.global_greybtn:hover
{
  color: #FFFFFF;
  background-color:#5D6F7B;
}

@media only screen and (max-width:991px){
  #home_solutionsubheader{
    font-size: 15px;
    margin-top: 2%;
    margin-bottom: 1%;
    padding-left: 10px;
    padding-right: 10px;
}
}

@font-face {
  font-family: 'asm_icons';
  src: url('../fonts/asm_icons.eot?9817510');
  src: url('../fonts/asm_icons.eot?9817510#iefix') format('embedded-opentype'),
  url('../fonts/asm_icons.woff?9817510') format('woff'),
  url('../fonts/asm_icons.ttf?9817510') format('truetype'),
  url('../fonts/asm_icons.svg?9817510#asm_icons') format('svg');
}
cx-global-message {
  display: none;
}
cx-asm-main-ui {
  display: none !important;
}
app-flu360-asm-login, app-flu360-asm-customer-seletcion,app-flu360-asm-cusomer-emulation {
  font-family: "Dosis", Helvetica, Arial, sans-serif !important;
  form {
  background: #27394f !important;
  padding: 15px 30px;
  display: flex ;
  // flex-direction: column !important;
  label {
    display: inline-flex;
    padding: 0px 10px;
    width: 100%;
    padding-top: 9px;
  }
  input {
    width:100%;
    height: 41px;
    padding: 6px 10px;
    font-size: 15px;
    width: 100%;
  }

  input.ng-invalid.ng-untouched, input.ng-touched.ng-dirty.ng-valid {
    border: none;
  }
  }
  input::placeholder {
    font-family: 'asm_icons', sans-serif;
    font-size: 11px;
    }
  button[type=submit] {
    background: #fad714;
    width: 20%;
    font-size: 16px;
    height: 36px;
    border: none;
    font-weight: 300 !important;
    text-transform: uppercase;
    color: #000 !important;
    // font-family: "Dosis", Helvetica, Arial, sans-serif;
   }
   button[type=submit]:disabled {
    opacity: 0.45;
   }
  #asm-password-wrapper {
    display: flex;
  }
  #asm-email-wrapper::before{
    font-family: 'asm_icons', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    content: '\e810';
    color: #bfc2cb;
    width: 40px;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 12px 12px;
    font-size: 14px;
    display: inline-flex;
  }
  #asm-password-wrapper::before{
    font-family: 'asm_icons', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    content: '\e811';
    color: #bfc2cb;
    width: 40px;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 12px 12px;
    font-size: 14px;
    display: inline-flex;
  }
  cx-icon, cx-form-errors {
    display: none !important;
  }
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
  {
      display: none !important;
  }
  .mdc-radio__outer-circle {
    border-radius: 0%;
    border-color: white !important;
  }
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle{
    border-color: red;
    border-radius: 0%;
  }
}
app-flu360-asm-cusomer-emulation {
  background: #27394f !important;
  padding: 15px 30px;
  display: flex ;
}

.mdc-label {
  color: white;
}

.customrSearchForm {
  flex-direction: column !important;
}
// .ASM_btn.collapseBtn.showSvg {
//   margin-top: 54px !important;
// }
app-flu360-asm-customer-seletcion > form > label::before {
  content: '\e810';
  font-family: 'asm_icons', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    height: 40px;
    color: #bfc2cb;
    width: 40px;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    padding: 14px 14px;
    font-size: 14px;
    display: inline-flex;
}
app-flu360-asm-customer-seletcion form input[type="text"] {
  height: 40px;
}

app-flu360-asm-customer-seletcion form button[type=submit] {
  margin-left: 150px;
  background-color: #19bc33 !important;
  color: #fff !important;
  font-weight: 400;
  font-size: 19px;
  border: 1px solid #19bc33 !important;
  border-radius: 0px !important;
}
@media (max-width: 667px) {
  app-flu360-asm-login form {
      display: flex;
      flex-direction: column;
  }
  app-flu360-asm-login, app-flu360-asm-customer-seletcion  {
    form > label {
      padding: 0px;
    }
  }
  app-flu360-asm-customer-seletcion form button[type=submit] {
    margin-left: 0px;
  }

}

a{
  color: inherit ;
  text-decoration: none;
}
cx-page-layout.AccountPageTemplate cx-page-slot,cx-page-layout.LandingPage5Template cx-page-slot,cx-page-layout.InvoicePageTemplate cx-page-slot, cx-page-slot.paymentInformationPageTemplate, cx-page-layout.CartPageTemplate cx-page-slot, cx-page-layout.OperationalEfficiencyNewPageTemplate cx-page-slot, cx-page-layout.emailverification cx-page-slot,cx-page-layout.ClinicalSupportNewPageTemplate cx-page-slot, cx-page-layout.Influenza65PlusNewPageTemplate cx-page-slot, cx-page-layout.JoinAccountTemplate cx-page-slot, cx-page-layout.AllProductsPageTemplate cx-page-slot, .RealWorldEvidencePageTemplate cx-page-layout,.AboutSeqirusPageTemplate cx-page-slot, cx-page-layout.CreateOrderPageTemplate cx-page-slot,cx-page-layout.FaqsPageTemplate cx-page-slot, cx-page-layout.fluadClinicalDataPageTemplate cx-page-slot,.cx-page-layout .adjuvantedFluadPageTemplate cx-page-slot,cx-page-layout .FlucelvaxPageTemplate cx-page-slot,cx-page-layout .CookiePolicyPageTemplate cx-page-slot,cx-page-layout .ContentPage1Template cx-page-slot,cx-page-layout .fluadRealWorldEvidencePageTemplate cx-page-slot, cx-page-layout .PublicErrorPageTemplate cx-page-slot,cx-page-layout .efficacyandSafetyPageTemplate cx-page-slot, cx-page-layout .AfluriasPageTemplate cx-page-slot,cx-page-layout .ErrorPageTemplate cx-page-slot , cx-page-layout .FluadPageTemplate cx-page-slot, cx-page-layout .PrescribingInformationPageTemplate cx-page-slot,cx-page-layout .InfleunzaNewPageTemplate cx-page-slot, cx-page-layout .ErrorPageTemplate cx-page-slot, cx-page-layout .CodingAndBillingNewPageTemplate cx-page-slot, cx-page-layout .ResourcesDetailsPageTemplate cx-page-slot, cx-page-layout .ResourcesLandingNewPageTemplate cx-pageslot,cx-page-layout .RealWorldEvidencePageTemplate cx-page-slot,cx-page-lsyout .cellBasedSolutionPageTemplate cx-page-slot{
  align-items: flex-start;
  max-width: none;
  padding-top: 0;
  padding-inline-end: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  margin: 0px;
}

// join account changes
.joinAccount_nagivationName1{
  font-family: 'Campton-Medium';
  font-size: 32px;
  line-height: 40px;
  color:#3c3c3c;
}
.joinAccount_nagivation .joinAccount_nagivationName2UK {
  color: #5D6F7B;
}
.joinAccount_nagivationName2{
  font-family: 'Campton-Book';
  font-size: 18px;
  line-height: 27px;
  margin-top: 40px;
}
.joinAccount_label{
  font-size: 24px;
  font-family: 'Campton-Medium';
  color: #2A3237;
  padding-bottom: 30px;
}
.joinAccount_text1{
  font-size: 16px;
  color: #5D6F7B;
  font-family: 'Campton-Book';
}
.joinAccount_text2{
  margin-bottom: 20px;
  margin-top: 4px;
  padding-bottom: 20px;
}
.joinAccount_text2 a{
  color: inherit;
  font-family: 'Campton-SemiBold';
  font-size: 16px;
  text-decoration: none;
}
.joinAccount_text2 a:hover,
.joinAccount_text2 a:focus{
text-decoration: underline !important;
color: #2A3237 !important;
}
.global_blackarrow
{
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/blackarrow.png);
    margin: 0% 10px;
    width: 28px;
    position: absolute;
    height: 22px;
    display: inline-block;
}

.joinAccount .checkbox-button{
  font-family: 'Campton-Book';
  font-size: 14px;
  line-height: 21px;
}
.joinAccount .checkbox-button__input{
  opacity: 0;
  position: absolute;
}
.joinAccount .checkbox-button__control{
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: inherit;
  color: #EA181B;
  border: 1.5px solid #666;
}
.joinAccount .checkbox-button__input:checked+.checkbox-button__control:after{
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
  background-color: #EA181B;
}
.joinAccount .checkbox-button__input:checked+.checkbox-button__control{
  border-color: #666;
}
.joinAccount .checkbox-button__control{
  transform: scale(0.75);
}
.joinAccount_text7 p{
	font-size:18px;
	line-height:27px;
}
@media only screen and (max-width: 991px) {
  .joinAccount_nagivationName2 {
      margin-top: 24px;
  }
  .joinAccount_label{
    padding-bottom: 0px !important;
  }
  .joinAccount_text1 {
    padding-bottom: 15px !important;
    margin-top: 15px;
    line-height: 24px;
}
.joinAccount_text2 .global_blackarrow{
    margin-left: 3px;
}
}
//cx-page-layout.AccountPageTemplate {
  // display: flex;
  // display: grid;
  // grid-template-areas: 'menu banner banner' 'menu section1 section2' 'menu section1 section2' 'menu helpsection helpsection';
//}
cx-page-layout.AccountPageTemplate:has(.BodyContent app-jobbagcode-uk){
  padding-bottom: 0 !important;
}
cx-page-layout.AccountPageTemplate,cx-page-layout.LandingPage5Template,cx-page-layout.InvoicePageTemplate,cx-page-layout.paymentInformationPageTemplate, cx-page-layout.CartPageTemplate, cx-page-layout.CreateOrderPageTemplate,cx-page-layout.JoinAccountTemplate,cx-page-layout.AllProductsPageTemplate,cx-page-layout.FaqsPageTemplate, cx-page-layout.fluadClinicalDataPageTemplate,cx-page-layout .RealWorldEvidencePageTemplate, cx-page-layout.adjuvantedFluadPageTemplate,cx-page-layout.FlucelvaxPageTemplate,cx-page-layout .CookiePolicyPageTemplate, .fluadRealWorldEvidencePageTemplate,cx-page-layout.efficacyandSafetyPageTemplate,cx-page-layout.ReturnsPageTemplate,cx-page-layout.ReturnsNewPageTemplate,cx-page-layout.AfluriasPageTemplate, cx-page-layout.ErrorPageTemplate,cx-page-layout.FluadPageTemplate, cx-page-layout.PrescribingInformationPageTemplate, cx-page-layout.ErrorPageTemplate, cx-page-layout.ResourcesDetailsPageTemplate, cx-page-layout.RegistrationAccountTemplate, cx-page-layout.ResourcesLandingNewPageTemplate,cx-page-layout.RealWorldEvidencePageTemplate,cx-page-layout.cellBasedSolutionPageTemplate,.seqirusb2b-uk cx-page-layout.ResourcesLandingTemplate,.seqirusb2b-uk cx-page-layout.SeqirusDynamicPageTemplate {
  .SideContent {
    width: 16.667%;
    float: left;
    max-width: none;
    padding-top: 0;
    padding-inline-end: 0;
    padding-bottom: 0;
    padding-inline-start: 0;
    margin: 0px;
    border-right: 1px solid #EEF0F1;
  }

  .BodyContent, .CenterLeftContentSlot {
    width: 83.333%;

    @media (max-width:991px) {
  width: 100%;
    }

    max-width: none;
    padding-top: 0;
    padding-inline-end: 0;
    padding-bottom: 0;
    padding-inline-start: 0;
    margin: 0px;
    align-items: flex-start;
    // display: grid;
    // grid-template-areas: 'banner banner banner banner' 'section1 section1 section2 section2' 'section3 section3 section2 section2' ;

    // app-banner-section-dashboard {
    //   grid-area: banner;
    // }

    // app-news-announcement-component {
    //   grid-area: section1;
    // }

    // app-recent-order {
    //   grid-area: section2;
    // }

    // app-open-credit-invoice-dashboard {
    //   grid-area: section3;
    // }

    // app-help-section-dashboard {
    //   grid-area: helpsection;
    // }
     app-news-announcement-component {
      padding: 2% 6% 2% 8%;
      margin: 1% 0%;
    }
  }
}
.LandingPage2Template .homepageBanner {
  app-custom-user-guide{
    display: none;
  }
}
.AccountPageTemplate .BodyContent,.LandingPage5Template .BodyContent,.InvoicePageTemplate .BodyContent,.ClinicalSupportNewPageTemplate .BodyContent, .OperationalEfficiencyNewPageTemplate .BodyContent, .Influenza65PlusNewPageTemplate .BodyContent, .AboutSeqirusPageTemplate .BodyContent,.PublicErrorPageTemplate .BodyContent,.InfleunzaNewPageTemplate .BodyContent,.ErrorPageTemplate .BodyContent,.CodingAndBillingNewPageTemplate .BodyContent,.ResourcesLandingNewPageTemplate .BodyContent,.emailverification .BodyContent{
display: grid;
@media (max-width:991px) {
display:block;
    }
    grid-template-columns: 50% 50%;
    app-banner-section-dashboard,app-custom-top-banner,app-sch-pay-top-banner,app-scheduled-payments-section,app-contact-us-topbanner,app-general-support,app-general-support-lo-slides {
    grid-column: 1 / span 2;
    }
    app-custom-user-guide{
      margin-left: -10px;
    grid-column: 1 / span 2;
    }
    app-recent-order{
    grid-row-end: span 2;
    }
    app-help-section-dashboard,app-custom-manage-location,app-custom-info{
    grid-column: 1 / span 2;
    }

}
.GigyaRegistrationPageTemplate .BodyContent .createprofile_helptext{
  padding-right: 13%;
  margin-bottom: 0px;
}

cx-page-layout.LandingPage2Template cx-page-slot {
  width: 375.333px
}
#home_planninglearn .global_whitearrow{

  @media (max-width:991px){
  display: inline;
  float: left;
  }
}
#home_planninglearn a{
  @media (max-width:991px){
      width: fit-content;
      float: left;
      }
}
@media only screen and (max-width: 991px) {
  .login_container {
       padding: 40px 20px !important;
      width: fit-content !important;
      box-shadow: none;

  }
}
// body{
//   position: relative;
//     float: left;
//     font-size: 14px;
//     font-family: 'Campton-Book' !important;
//     box-sizing: border-box;
//     overflow-x: hidden;
//     margin: 0 !important;
//     padding: 0;
// }
// html,body{
//   font-family: campton-book;
//   -webkit-text-size-adjust: 100%;
//   -ms-text-size-adjust: 100%;
// }
.gigya-screen .gigya-composite-control.gigya-composite-control-header.verify_header{
  margin-top: 20px !important;
  float: left;
  width: 100%;

}
.gigya-screen .gigya-composite-control.gigya-composite-control-label.verify_subheader{
  float: left;
}

.GigyaEmailVerificationPageTemplate .gigya-composite-control.gigya-composite-control-label.verify_subheader{
  display: block !important;
  float: none;
}
/* .TopHeaderSlot {
  width: 100% !important;
} */
.BodyContent app-alert-banner , .BodyContent app-order-summary,.BodyContent app-all-invoices-banner-section, .BodyContent app-all-invoices-left-section,.BodyContent app-homepage-uk-pislot,.BodyContent app-my-orders-dashboard, .BodyContent app-uk-my-orders-left-section, .BodyContent app-orders-cart-review-page, .BodyContent app-amend-order-thank-you-page, .BodyContent app-orders-cart-sorry-page,.BodyContent app-confirm-order-thank-you-page {
  grid-column: 1 /span 2;
}

.float_left{
float:left;
}
app-flu360-asm-customer-seletcion .asm-results {
  border: none !important;
    // border: solid 1px #89919a;
    position: absolute;
    line-height: 1.5rem;
    left: 2.5rem;
    z-index: 11;
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    max-width: 50vw;
    max-height: 70vh;
    overflow-y: auto;
    top: 8rem;
    width: 40.4%;

  button {
    margin: 0;
    appearance: none;
    outline: 0;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
  //  white-space: nowrap;
    background-color: transparent;
    color: #51555a;
    display: flex;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 2px !important;
  }
  .result-button-id:hover, .result-button-id:active, .result-button-id:focus {
    border: 1px solid #BE1114 !important;
    background-color: #BE1114 !important;
    color: #FFFFFF !important;
  }
  button[tabindex="-1"] {
    display: flex !important;
  }
}
.asm-uk-result{
  top: 11.4rem !important;
}

app-flu360-asm-customer-seletcion .result-name {
  width: 24%;
  font-family: "Dosis", Helvetica, Arial, sans-serif;
  font-size: 18px;
  // color: #fff;
  vertical-align: top;
  display: inline-block;
  overflow-wrap: break-word;
  // padding-left: 3px;
}
app-flu360-asm-customer-seletcion .result-id {
  width: 43%;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-left: 20px;
font-family: "Dosis", Helvetica, Arial, sans-serif;
font-size: 18px;
// color: #fff;
vertical-align: top;
display: inline-block;
}
app-flu360-asm-customer-seletcion .resultidthird{
  width: 33%;
}

//reg thank you page
.registration_thankyou_help a{
  text-decoration: none;
  color: #2A3237;
  font-family: 'Campton-SemiBold';
  cursor: pointer;
}
.registration_thankyou_rightheader span{
  font-family: 'Campton-SemiBold';
  cursor: default;
}
.registration_thankyou_rightheader a,.registration_thankyou_rightheader a:focus{
  text-decoration: none;
  color: #2A3237;
  font-family: 'Campton-Book';
}
@media (min-width: 1024px) {
  cx-page-layout.AccountPageTemplate,cx-page-layout.LandingPage5Template,cx-page-layout.InvoicePageTemplate,cx-page-layout.paymentInformationPageTemplate, cx-page-layout.CartPageTemplate, cx-page-layout.CreateOrderPageTemplate,cx-page-layout .JoinAccountTemplate, cx-page-layout .AllProductsPageTemplate, cx-page-layout  .FaqsPageTemplate, cx-page-layout .fluadClinicalDataPageTemplate,.FlucelvaxPageTemplate cx-page-layout,.CookiePolicyPageTemplate cx-page-layout,.adjuvantedFluadPageTemplate cx-page-layout,.fluadRealWorldEvidencePageTemplate cx-page-layout,.fluadRealWorldEvidencePageTemplate cx-page-layout,cx-page-layout.efficacyandSafetyPageTemplate,cx-page-layout.AfluriasPageTemplate,cx-page-layout.ResourcesDetailsPageTemplate,cx-page-layout.RealWorldEvidencePageTemplate,cx-page-layout.cellBasedSolutionPageTemplate{
    .SideContent {
      width: 16.667% !important;
    }
    .BodyContent, .CenterLeftContentSlot {
      width: 83.333% !important;
    }
}
}

@media (min-width: 1024px) {
  cx-page-layout.ErrorPageTemplate {
      .BodyContent {
        width: 100% !important;
      }
  }
}

/* .AccountPageTemplate .header .SideContent,.InvoicePageTemplate .header .SideContent,.paymentInformationPageTemplate .header .SideContent, .CartPageTemplate .header .SideContent, .CreateOrderPageTemplate .header .SideContent,.AllProductsPageTemplate .header .SideContent,.RealWorldEvidencePageTemplate .header .SideContent, .FaqsPageTemplate .header .SideContent, .fluadClinicalDataPageTemplate .header .SideContent,.FlucelvaxPageTemplate .header .SideContent,.CookiePolicyPageTemplate .header .SideContent,.adjuvantedFluadPageTemplate .header .SideContent,.ContentPage1Template .header .SideContent,.fluadRealWorldEvidencePageTemplate .header .SideContent,.efficacyandSafetyPageTemplate .header .SideContent,.AfluriasPageTemplate .header .SideContent,.ErrorPageTemplate .header .SideContent,.FluadPageTemplate .header .SideContent,.PrescribingInformationPageTemplate .header .SideContent,.ErrorPageTemplate .header .SideContent,.ResourcesDetailsPageTemplate .header .SideContent,.InactivePageTemplate .header .SideContent,.ResourcesLandingNewPageTemplate .header .SideContent{
  width: 100% !important;
} */

#cc_c2a{
  z-index:9999 !important;
}
.save-license-dialog .mat-mdc-dialog-container{
  z-index:1060 !important;
}
.req-license-dialog, .save-license-dialog{
  width:70% !important;
  position: relative !important;
  bottom: -97px;
  left: 30px;
  .mat-mdc-dialog-surface{
    overflow-x:hidden !important;
    border-radius:0px !important;
    //padding:0px 12px;
  }
}
.req-license-dialog .mat-mdc-dialog-container{
  z-index:1060 !important;
  width:96%;
}
.thank-you-dialog
{
  .mat-mdc-dialog-container{
    z-index:1060 !important;
  }
  .mat-mdc-dialog-surface{
    border-radius: 0px !important;
  }
}
.cdk-overlay-container{
  z-index:1060 !important;
  background-color: rgba(0, 0, 0, 0.5);
}
.AccountPageTemplate .BodyContent:has(app-org-location){
  display: block;
  float:left;
}
.AccountPageTemplate .BodyContent:has(app-uk-all-invoice-top-banner){
  display: block;
  float:left;
  position: relative;
  app-invoice-details-section{
    min-height: 544px;
    float: left;
  }
  app-jobbagcode-uk{
    position: absolute;
    bottom: 0;
    left: -23%;
    .grbnumber{
      color: #5d6f7b;
      margin: 0px 10px 20px;
      padding-top: 10px;
    }
  }
}
.AccountPageTemplate .BodyContent:has(app-uk-all-invoice-top-banner){
  display: block;
  float:left;
}

.AccountPageTemplate .BodyContent:has(app-all-invoices-banner-section){
  app-custom-user-guide{
    margin-left: -24px;
  }
}
.AccountPageTemplate .BodyContent:has(app-org-location){
 position:relative;
 app-homepage-uk-pislot{
    position: absolute;
    top: 190px;
    left: 0;

 }
 app-jobbagcode-uk{
      margin-left: -21%;
      position: absolute;
      bottom: 0;
      left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .BodyContent:has(app-current-season-status){
    display: flex !important;
  }
  .BodyContent:has(app-current-season-status) app-alert-banner{
    order :1;
  }
  // .BodyContent:has(app-current-season-status) app-order-summary{
  //   order :2;
  // }
  .BodyContent app-order-summary{
    order:2
  }
  .BodyContent:has(app-current-season-status) app-custom-user-guide{
    order:3
  }
  .BodyContent app-order-overview{
    order:4
  }
  .BodyContent app-current-season-status{
    order:5
  }
}
  @media (min-width: 1000px) and (max-width: 1100px) {
    .AccountPageTemplate .createprofile_helptext {
      text-align: right !important ;
      padding: 0px 25px !important;
      padding-right: 5% !important;
    }
}
@media only screen and (max-width: 991px) {
  #locationstable tbody{
    display: flex !important;
    flex-direction:column;
   }
}

.locations_subheader a {
  font-family: campton-medium;
  font-weight: 600;
  text-decoration: underline !important;
  color:inherit!important;
}
.locations_subheader b{
  font-weight:bold;
}
.locations_addsubheader a
{
    font-family:campton-bold;
    text-decoration: underline !important;
    color:#2A3237;
}
//myorders
.productdetailTable thead tr th {
  font-size: 10px !important;
  line-height: 15px;
  font-family: "Campton-Medium";
  color: #5D6F7B !important;
  text-align: left !important;
  padding: 0px 0px 10px 0px !important;
  font-weight: 600;
}
.productdetailTable thead tr th:first-child {
  width: 25%;
}
.productdetailTable thead tr th:nth-child(2) {
  width: 14%;
}
.productdetailTable thead tr th:nth-child(3) {
  width: 16%;
}
.productdetailTable thead tr th:nth-child(4) {
  width: 17%;
}
.productdetailTable thead tr th:nth-child(5) {
  width: 28%;
  padding-left: 10px !important;
}
.productdetailTable tbody tr.mdc-data-table__row td {
  color: #2A3237;
  font-family: "Campton-Book";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  padding: 12px 5px 12px 0px;
  vertical-align: middle;
  border-top: 1px solid #DBE0E2;
  border-bottom: none;
}
app-my-orders-left-section{
  padding-top: 20px;
  grid-column: 1 / span 2;
}
.order_details_section{
.mat-mdc-progress-bar{
  height: 8px;
}
.progressUK .mat-mdc-progress-bar{
  height: 10px;
}
.progressUK .mdc-linear-progress__bar-inner {
  border-color: #5cb85c;
  border-radius: 0;
  border-top-width: 10px;
}
 .mdc-linear-progress__buffer-bar,.mdc-linear-progress__buffer{
  height: 8px;
  border-color: #EEF0F1;
  background-color: #EEF0F1;
  border-radius: 4px;
  border-top-width: 8px;
}
.mdc-linear-progress__bar{
  height: 8px;
}
.progressUK .mdc-linear-progress__bar{
  height: 10px;
}
 .mdc-linear-progress__bar-inner{
  border-color: #5D6F7B;
    border-radius: 4px;
    height: 8px;
    border-top-width: 8px;
}
}
#locations_tymodal a
{
    font-family:campton-bold;
    text-decoration: underline !important;
    color:#5D6F7B;
}

//feedbackform change
.AccountPageTemplate .BodyContent:has(app-contact-us-form),.LandingPage5Template .BodyContent:has(app-contact-us-form){
  grid-template-columns: 58% 42%;
}
.contact_email,.contact_call{
  font-size: 16px;
  font-family: 'CamptonSemiBold';
  margin-top: 32px;
  color: #2A3237;
}
.contact_email a,.contact_call a{
  color: #2A3237;
  text-decoration: underline !important;
}
.Support_call {
  margin: 8px 0px;
  font-family: "Campton-Bold";
  a{
    text-decoration: underline !important;
    display: inline;
  }
}
.Support_email{
  margin-top: 20px;
  font-family: "Campton-Bold";
  a{
    text-decoration: underline !important;
    display: inline;
  }
}
.help_smallcaps {
  font-size: 80%;
}
@media only screen and (max-width:340px){
  .contact_email a, .contact_call a{
      font-size: 14px;
  }
}
@media only screen and (max-width:991px){
.contact_email{
  margin-top: 24px;
}
}

cx-form-errors p{
	font-style: normal;
    font-family: 'Campton-Book';
    font-size: 12px;
    color: #BE1113;
    font-weight: 600;
    position: relative;
    word-wrap: nowrap;
    /* word-break: break-all; */
    padding-left: 2px;
}

cx-form-errors p::after,cx-form-errors p::before{
  display:none;
}
//finanical dashboard

app-invoice,app-helpsection,app-financial-banner{
  grid-column: 1 / span 2;
}
.financial_table thead tr th ,.financial_table tbody tr td{
  width: 15% !important;
}
.financial_table thead tr th:last-child,.financial_table tbody tr td:last-child {
  width: 18% !important;
}
.financial_table thead tr th {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Campton-Medium';
  color: #2A3237;
  background-color: #F9FAFA !important;
  padding: 10px 0px 10px 10px;
  text-transform: capitalize;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 16;
  border-bottom: none;
}
.financial_table tbody tr td {
  padding: 13px 0px 13px 10px;
  color: #5D6F7B;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Campton-Book';
  position: relative;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  border-bottom: none;
}
.financial_table tbody tr:nth-child(even) {
  background-color: #F9FAFA;
}
.financial_table tbody tr td:last-child span {
  font-size: 12px;
  font-family: 'CamptonSemiBold';
  color: #2A3237;
  float: left;
  text-transform: capitalize;
}
.financial_table tbody tr td:last-child span {
  width: 120px;
}
.paidtable tbody tr td:last-child span{
width: 90px;
}
 .financial_table tbody tr td:last-child .global_blackarrow {
  width: 24px;
  margin-left: -2px;
  margin-right: 5px;
  right: 5px;
  margin-top: 2px;
}
.financial_table tbody tr td:last-child .global_blackarrow {
  float: right;
  height: 18px;
}
.credittable thead tr th:nth-of-type(1) {
  width: 30% !important;
  padding: 28px 0px 28px 20px;
  text-align: left !important;
}
.credittable thead tr th {
  text-align: left;
  padding: 28px 0px 28px 20px;
  background-color: #F9FAFA !important;
  font-size: 14px !important;
  text-transform: capitalize;
  color: #2a3237 !important;
  font-family: 'Campton-Medium';
  font-weight: 600;
  border-bottom: none;
  position: sticky;
}
.credittable thead tr th:nth-of-type(2), .credittable thead tr th:nth-of-type(3) {
  width: 16% !important;
  padding: 28px 10px 28px 10px;
  text-align: left !important;
}
.credittable thead tr th:nth-of-type(4) {
  width: 18% !important;
  padding: 28px 20px 28px 10px;
  text-align: left !important;
}
.credittable thead tr th:nth-of-type(5) {
  width: 25% !important;
  padding: 28px 0px;
  text-align: left !important;
}
.credittable tbody tr td {
  padding: 16px 0px 16px 20px;
  width: 22%;
  position: relative;
  text-align: left;
  font-size: 14px;
  color: #5d6f7b;
  vertical-align: middle;
  border-bottom: none;
  font-size: 14px;
  color: #5d6f7b;
  line-height: 21px;
  font-family: "Campton-Book";
}
.credittable tbody tr td:nth-of-type(1) {
  width: 30% !important;
  padding: 16px 0px 16px 20px;
  text-align: left !important;
}
.credittable tbody tr td:nth-of-type(2), .credittable tbody tr td:nth-of-type(3) {
  width: 15% !important;
  padding: 16px 10px 16px 10px;
  text-align: left !important;
}
.credittable tbody tr td:nth-of-type(4) {
  width: 15% !important;
  padding: 16px 20px 16px 10px;
  text-align: left !important;
}
.credittable tbody tr td:nth-of-type(5) {
  width: 25% !important;
  padding: 16px 0px;
  text-align: left !important;
}
.credittable tr:nth-child(odd) td {
  background-color: #FFFFFF;
}
.credittable tr:nth-child(even) td {
  background-color: #F9FAFA;
}
.mat-sort-header-content{
text-align:left !important;
}
.credittable tbody tr td:last-child span {
  font-size: 16px;
  font-family: 'CamptonSemiBold';
  color: #2A3237;
  cursor: pointer;
  float: left;
}
.credittable tbody tr td:last-child .global_blackarrow {
  float: right;
  margin-left: 10px;
}
.financial_openinvoiceMobiletable-1 table thead,.mobcredittable thead{
  display: none;
}
.financial_openinvoiceMobiletable-1 tbody tr td ,.mobcredittable tbody tr td{
  padding: 0px;
}
.financial_valueName p{
  margin-bottom: 0px;
  font-family: "Campton-Book", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #5d6f7b;
 }
 .financial_openinvoiceMobiletable-1 tbody tr td:last-child span{
  width: fit-content;
  padding-right: 40px;
  font-size: 16px;
 }
 .financial_openinvoiceMobiletable-1 tbody tr td:last-child .global_blackarrow{
  margin-top: 2px;
 }
 .financial_openinvoiceMobiletable-1 tr:nth-child(odd) td,.mobcredittable tr:nth-child(odd) td{
  background-color: #FFFFFF;
}
.financial_openinvoiceMobiletable-1 tr:nth-child(even) td,.mobcredittable tr:nth-child(even) td{
  background-color: #F9FAFA;
}
.financial_supportheader {
  font-size: 18px;
  line-height: 27px;
  font-family: 'Campton-Medium';
  color: #2A3237;
}
.financial_supportfhelp {
  font-size: 14px;
  font-family: 'Campton-Book';
  line-height: 21px;
  margin-top: 15px;
}
.billing{
color: #2A3237;
font-family: "Campton-semibold";
font-size: 16px;
font-weight: 600;
letter-spacing: 0;
line-height: 24px;
}
.billingvalue{
font-family: "Campton-Book";
font-size: 16px;
color: #2A3237;
margin-top: 10px;
margin-bottom: 10px;
}
.mobiletable tbody tr td,.mobile_prod_table{
  display: flex;
  width: 100%;
}
.mobile_prod_table{
  justify-content: space-between;
  padding: 5px;
}
.tabledivorders{
  width: 100%;
}
.mobiletable tbody{
display: flex;
width: 100%;
flex-direction: column;
}

.table_header {
  color: #2A3237;
  font-family: "Campton-SemiBold";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.table_value{
  font-family: "Campton-Book";
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;

}
.table_product{
  font-family: "Campton-Book";
    font-size: 16px;
    line-height: 24px;
}
.underline-links a{
  text-decoration: underline !important;
  font-family: 'Campton-Semibold';
  font-weight: 700;
}
.invoice_paypopuptext {
  color: #5D6F7B;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  font-family: 'Campton-Book';
  margin-bottom: 24px;
}
.invoice_paypopuptextheader {
  color: #5D6F7B;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  font-family: 'Campton-SemiBold';
  margin-bottom: 15px;
}
.invoice_paypopuptext {
  color: #5D6F7B;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  font-family: 'Campton-Book';
  margin-bottom: 24px;
}
.invoice_paypopuptextheader {
  color: #5D6F7B;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  font-family: 'Campton-SemiBold';
  margin-bottom: 15px;
}
.invoice_paypopupbutton {
  background-color: #5D6F7B;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  font-family: 'Campton-SemiBold';
  padding: 8px 30px;
  width: 155px;
  margin: 40px auto 20px;
}
.invoice_AccountReceivable-text {
  color: #5D6F7B;
  font-family: 'Campton-Book';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
}

#locationstable .mat-sort-header-arrow{
  display:none !important;
}
#locationstable thead tr{
  background-color: #F9FAFA;
 // height: 18px;
  th .mat-sort-header-container{
    outline:none !important;
  }
}
#locationstable tbody .mat-mdc-row
{
  height: 48px;
}


#locationstable tbody tr:nth-child(4n+3){
  background-color: #F9FAFA;
}
.changepasssuccess_loginbtn:focus, .changepasssuccess_loginbtn:hover {
  color: #ffffff;
  background-color: #EA181B !important;
  box-shadow: none !important;
}
body{
  font-size: 14px !important;
  line-height: 1.42857143 !important;
}
.no-matching{
  text-align: center !important;
  font-size: 16px;
  font-family: campton-Book;
  margin-bottom: 5px;
  color: #5D6F7B;
  padding: 20px 0px 20px 10px !important;
  width: 100%;
  display: block !important;
  }
  .nodata{
    color: #2A3237;
    font-family: "Campton-Book";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding: 12px 5px 12px 0px;
    vertical-align: middle;
    text-align: center;
    display: grid;
    width: 400%;

  }
.productdetailTable .expanded-row td{
  border-bottom: none;
}
  .anchorunderline:hover, .anchorunderline:focus {
    text-decoration: underline !important;
}

@media only screen and (max-width:991px){
  app-payment-information{
    overflow-x: hidden;
    width:100%;
  }
}
@media only screen and (min-width:768px) and (max-width:1024px){
  app-payment-information .savedcard-parent{
 max-width:100%;
 height:auto;
     overflow-x: hidden;
  }
}
.p-inputmask{border: none !important;}

.GigyaRegistrationPageTemplate {
  app-custombreadcrumb{
    width: 50%;
    display: block;
    float: left;
  }
  .BodyContent:has(app-custombreadcrumb){
    cx-paragraph{
      width: 47%;
      float: left;
      margin-top: 10px;
    }
  }
  app-custom-gigya-raas{
    float:left;
  }
}
cx-storefront.stop-navigating:after {
  height: 0px;
}
cx-storefront.stop-navigating:before, cx-storefront.start-navigating:before {

  height: 0px;
}
.AccountPageTemplate .account-section-content.createprofile_cdccontent{
  padding-right: 31px;
}
.GigyaLoginPageTemplate .jobbagsubchild {
  // left: 0px !important;
  // bottom: -92px !important;
  bottom: -3px !important;
  left: -3% !important;
  label {
    font-size: 14px;
    font-family: 'Campton-Book';
    color: #2a3237 !important;
  }
}

.GigyaLoginPageTemplate .BodyContent{
  .is-initialized{
    img{
      object-position: top;
      min-height: 410px;
      object-fit: cover;
    }
  }
}

.VerificationEmailSentPageTemplate{
  .verifylink_setsuppbtncontainer{
    margin-bottom: 45px !important;
    margin-top: 30px;
  }
}
.laptop-paragraph-content ul li {
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #5d6f7b;
}

.AccountPageTemplate .BodyContent:has(app-banner-section-dashboard) app-custom-user-guide{
  margin-left: 0px;
}
.AccountPageTemplate .BodyContent:has(app-custom-account-info) .account-section-content{
  padding-right: 38px;
}
cx-storefront{

overflow-x:hidden
 }
.AccountPageTemplate .BodyContent:has(app-banner-section-dashboard) .createprofile_helptext,.AccountPageTemplate .BodyContent:has(app-custom-account-info) .createprofile_helptext{
  padding-right: 50px;
}

.AccountPageTemplate .BodyContent:has(app-custom-account-info){
  grid-template-columns: 45% 55%;
}

.dashboard-support-containeruk .dashboard-support-emaillinkuk2{
  color: #2A3237;
  font-family: Campton-medium;
  font-size: 18px;
  text-decoration: underline !important;
  padding: 5px 0;
}
//clinincal-support
.ClinicalSupportNewPageTemplate p.text--red-100.whatif--card-header{
  color: #3c9a90;
}
.ClinicalSupportNewPageTemplate p.text--red-100.callout{
  color: #3c9a90;
}
.ClinicalSupportNewPageTemplate p.text--teal-100.whatif--teal-card-header{
  color: #5d6f7b;
}
.ClinicalSupportNewPageTemplate p.text--teal-100.calloutteal{
  color: #5d6f7b;
}
.ClinicalSupportNewPageTemplate .breadcrumbs-opeff{
  display: none;
 }
 .ClinicalSupportNewPageTemplate p.text--grey-110{
  width:455px !important;
 }

 .ClinicalSupportNewPageTemplate .join360-clinical-container .join360--hero-container .cells-360-bg {
     position: relative;
 }
 @media (min-width: 1024px) {
     .join360-clinical-container .join360--hero-container .cells-360-bg {
         top: -178px;
         left: 50%;
         width: 627px;
         display: block;
     }
 }
 //-breadcrums end
 .Influenza65PlusNewPageTemplate .text--grey-110{
     width:450px;
 }
.mini-card .ash1 span {
  color: #ea181b;
}
#influenza-burden-65plus .two-row--card .two-row--card-bottom .ash1 {

  font-size: 16px;
  line-height: 22px;
  color: #5d6f7b;
}
.facts-card .card-content .text span {
  color: #ea181b;
}
.facts-card .card-content .text1 span {
  color: #ea181b;
}
.p-inputmask{border: none !important;}



.referencesHomeUK {
  margin-top: 3%;
}
.referencesHomeUK .referencesContainerHomeUK {
  padding: 0% 52px 0% 52px;
  word-break: break-all;
}
.referencesHomeUK .referencesContainerHomeUK p {
  padding: 20px 0;
  font-size: 14px;
  color: #5d6f7b;
  letter-spacing: 0;
  line-height: 21px;
}
.referencesHomeUK .referencesContainerHomeUK p strong {
  font-family: "Campton-Bold", sans-serif;
}

cx-page-layout cx-page-slot app-homepage-uk-products{
  flex: none !important;
  width: 50% !important;
}
.jobbagsubchild {
  position: absolute;
  bottom: 85px !important;
}
a.footeritem, a.footeritem:hover {
  font-size: 16px !important;
  font-family: Campton-book !important;
  color: #fff !important;
  text-decoration: underline !important;
}
a.help_phonelink, a.help_phonelink:hover {
  font-size: 14px !important;
  font-family: Campton-book !important;
  color: #fff !important;
  text-decoration: underline !important;
}
a.help_maillink, a.help_maillink:hover {
  font-size: 14px !important;
  font-family: Campton-book !important;
  color: #fff !important;
  text-decoration: underline !important;
}

a.externallinkpopupUK, a.externallinkpopupUK:hover {
  font-size: 14px !important;
  font-family: Campton-book !important;
  color: #fff !important;
  text-decoration: underline !important;
}

a.dashboard-support-emaillink{
  text-decoration: underline !important;
}
//influenza//
@media(max-width:932px){
  .Influenza65PlusNewPageTemplate .reference{
      width:360px;
  }
  }
.mini-card .one-block-text span{
  color:#ea181b;
}
.season-card-left .card-callout {
  color: #ea181b;
  font-size: 56px !important;
}
.two-row--card-content .card-content .text--red-100 {
  color: #ea181b;
  font-family: "Campton-SemiBold", sans-serif !important;
}

#influenza-burden-65plus .hero--overview .hero--overview-content p span {
  font-family: "Campton-Bold", sans-serif;
  color: #ea181b;
}
.season-card-right-desktop .ash1 .callout{
  text-transform: uppercase !important;
}
.two-row--card-content .card-content .callout {
  text-transform: uppercase;
  font-family: "Campton-SemiBold", sans-serif !important;
}
.season-card-right-desktop .ash1 .line-header{
  text-transform: uppercase !important;
}
#season-card .seasons-two-row--card-bottom .line-header {
    text-transform: uppercase;
}
p.line-header:before {
  content: "";
  width: 48px;
  border: 1px solid #5d6f7b;
  position: absolute;
  margin-left: -57px;
  margin-top: 8px;
}
p.line-header:after {
  content: "";
  width: 48px;
  border: 1px solid #5d6f7b;
  position: absolute;
  margin-left: 9px;
  margin-top: 8px;
}
.Influenza65PlusNewPageTemplate p.callout{
  font-weight: bold;
  color: red;
  font-size: 56px;
}
//about-page//
.about-us span {
  font-family: "Campton-Bold", sans-serif;
  color: #ea181b;
}
.mini-card .ash1 span {
  color: #ea181b;
  font-size: 20px;
}
#influenza-burden-65plus .two-row--card .two-row--card-bottom .ash1 {

  font-size: 16px;
  line-height: 22px;
  color: #5d6f7b;
}
.mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow{
  opacity: 1 !important;
}

.login_container h2{
  font-family: "Campton-Medium", sans-serif;
    margin-bottom: 0px;
}
.show > .modal{
  display: block !important;
}
.AccountPageTemplate .BodyContent:has(app-homepage-uk-pislot){
  grid-template-columns: 48% 52%;
}
app-custom-profile-activation-popup {
  float: left;
}

//influenza

.season-card-right-desktop .ash1 .line-header{
  text-transform: uppercase !important;
}
#season-card .seasons-two-row--card-bottom .line-header {
    text-transform: uppercase;
}
p.line-header:before {
  content: "";
  width: 48px;
  border: 1px solid #5d6f7b;
  position: absolute;
  margin-left: -57px;
  margin-top: 8px;
}
p.line-header:after {
  content: "";
  width: 48px;
  border: 1px solid #5d6f7b;
  position: absolute;
  margin-left: 9px;
  margin-top: 8px;
}
.Influenza65PlusNewPageTemplate p.callout{
  font-weight: bold;
  color: red;
  font-size: 56px;
}
//about-page//
.about-us span {
  font-family: "Campton-Bold", sans-serif;
  color: #ea181b;
}
.joinAccount_text7UK p{
  font-size: 16px;
  line-height: 24px;
}
.join-loggedIn {
  .joinAccount_rightsideUK{
      padding-top: 72px;
  }
  .joinAccount_text1{
      font-size: 18px;
      line-height: 27px;
      padding-bottom: 24px;
  }
  .joinAccount_text2UK span{
      font-family: 'Campton-Book';
      font-size: 16px;
      color: #2A3237;
      line-height: 24px;
  }
}
.joinAccount_text2UK a{
  color: #2A3237 !important;
  }
  .joinAccount_text2UK{
    font-size: 16px;
    color: #5D6F7B;
    font-family: 'Campton-Book';
    }

.AccountPageTemplate .BodyContent:has(app-join-acc-thankyou){
      display: block;
      float:left;

      app-jobbagcode-uk{
        display: block;
        float: left;
      }
      app-join-acc-thankyou .help_flu360linkUK{
        font-family:campton-semibold;
        font-weight:600;
        cursor: pointer;
        text-decoration: underline !important;
        color: #2A3237 !important;
      }
}
 .joinAccount_top-support p a{
      text-decoration: underline !important;
 }

.joinAccloginText{
  .joinAccount_text1:nth-child(3){
      display: none;
  }
}
.helpnfaqcontainer a, .helpnfaqcontainer .help_phonelink{
  color: #5D6F7B !important;
  font-size: 16px !important;
  font-family: campton-medium !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.app-multiple-needs{
  margin-top: 8%;
}
.series0Line  {
  stroke-width: 1px !important;
  stroke: #979797 !important;
}
.financial_supportfhelp a, .financial_supportfhelp a:focus, .financial_supportfhelp a:hover {
  color: #2A3237  !important;
  font-family: 'Campton-SemiBold';
  text-decoration: underline !important;
  word-break: break-word;
}
.cdk-global-scrollblock{
  overflow: hidden !important;
  top:unset !important;
}
//for the page to be displayed 100% width in logged out version
body:not(.loggedin) .FaqsPageTemplate .BodyContent,body:not(.loggedin) .FlucelvaxPageTemplate .BodyContent,body:not(.loggedin) .CookiePolicyPageTemplate .BodyContent,body:not(.loggedin) .fluadClinicalDataPageTemplate .BodyContent, body:not(.loggedin) .cellBasedSolutionPageTemplate .BodyContent,body:not(.loggedin) .AllProductsPageTemplate .BodyContent,body:not(.loggedin) .RealWorldEvidencePageTemplate .BodyContent,body:not(.loggedin) .fluadRealWorldEvidencePageTemplate .BodyContent,body:not(.loggedin) .efficacyandSafetyPageTemplate .BodyContent, body:not(.loggedin) .AfluriasPageTemplate .BodyContent, body:not(.loggedin) .FluadPageTemplate .BodyContent, body:not(.loggedin) .PrescribingInformationPageTemplate .BodyContent,body:not(.loggedin) .ResourcesDetailsPageTemplate .BodyContent,body:not(.loggedin) .ResourcesLandingNewPageTemplate,body:not(.loggedin) .adjuvantedFluadPageTemplate .BodyContent,body:not(.loggedin) .LandingPage5Template .BodyContent
{
  width: 100% !important;
}
//for help and faq page template logged-in and logged-out need help hide and show
  body:not(.loggedin)  .FaqsPageTemplate .BodyContent{
    app-custom-user-guide{
      display: none !important;
    }
    app-custom-user-guide:nth-of-type(1){
      display: block !important;
    }
  }
  body.loggedin .FaqsPageTemplate .BodyContent{
    app-custom-user-guide{
      display: none !important;
    }
    app-custom-user-guide:nth-of-type(2){
      display: block !important;
    }
  }

  //accountPage
  .AccountPageTemplate{
    .invoice_AccountReceivable{
      .invoice_AccountReceivable-text{
        a{
          text-decoration: underline !important;
          font-family: 'CamptonSemiBold';
        }
      }
      .invoice_AccountReceivableClose{
            margin-bottom: 28px;
        }
    }
}



  //return
#forUKReturns .return_headerpara p {
  font-size: 14px;
  line-height: 21px;
  font-family: 'Campton-Book';
  color: #2A3237;
}
#forUKReturns .return_headerpara a {
  font-family: 'Campton-Medium';
  text-decoration: underline !important;
}
#forUKReturns .return_headerpara a:hover {
  font-family: 'Campton-Medium';
  text-decoration: none !important;
}
  .AccountPageTemplate app-multiple-soldto-popup{
    display: none;
  }
body.modal-open .LandingPage2Template{
  transform: none;
  position: static;
}
body.modal-open .LandingPage2Template .homepageBanner{
  position: relative;
  transform: translateX(0%);
}

body.loggedin .AllProductsPageTemplate app-first-comp:nth-child(2) {
  border-top: 1px solid #DBE0E2;
}
body:not(.loggedin) .AllProductsPageTemplate app-first-comp {
  float: left;
  max-width: 50%;
}
body:not(.loogedin) .AllProductsPageTemplate app-about-references {
  background-color: #f9fafa;
  position: relative;
  transform: translateX(0%);
}
.mobileUKRedirectionPageTemplate .redirect_redtext {
  color: #EA181B;
  font-family: campton-bold;
}
cx-storefront.mobileUKRedirectionPageTemplate{
  overflow-y: hidden;
}
.mat-typography:has(.mobileUKRedirectionPageTemplate),.mat-typography:has(.ExplicitConsentPageTemplate){
  letter-spacing: normal !important;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity:0
}

.thank-you-dialog{
  width: 80%;
  padding: 30px 15px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute !important;
  margin: 30px 0;
}
.locations_tysubheader a:focus {
  outline: 2px solid #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  outline-offset:-2px
}
.mobileUKRedirectionPageTemplate .redirect_redtext {
  color: #EA181B;
  font-family: campton-bold;
}
cx-storefront.mobileUKRedirectionPageTemplate{
  overflow-y: hidden;
}
.mat-typography:has(.mobileUKRedirectionPageTemplate){
  letter-spacing: normal !important;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity:0
}

.thank-you-dialog{
  width: 80%;
  padding: 30px 15px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute !important;
  margin: 30px 0;
}
.locations_tysubheader a:focus {
  outline: 2px solid #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  outline-offset:-2px
}
.mobileUKRedirectionPageTemplate .redirect_redtext {
  color: #EA181B;
  font-family: campton-bold;
}
cx-storefront.mobileUKRedirectionPageTemplate{
  overflow-y: hidden;
}
.mat-typography:has(.mobileUKRedirectionPageTemplate){
  letter-spacing: normal !important;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity:0
}

.thank-you-dialog{
  width: 80%;
  padding: 30px 15px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute !important;
  margin: 30px 0;
}
.locations_tysubheader a:focus {
  outline: 2px solid #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  outline-offset:-2px
}
.FluadPageTemplate button#nav-tab-0,button#nav-tab-1,button#nav-tab-2,button#nav-tab-3{
  height:15%;
  padding: 24px 32px !important;

 }
//  .RealWorldEvidencePageTemplate .product_efficacy-header {
//   margin: 0px 0px 64px !important;
//   background: linear-gradient(57.86deg, #3f961d 19.07%, #78be20 69.84%) !important;
//   background-clip: text !important;
//   left: 50%;
//   position: relative;
//   transform: translateX(-46%);
// }
.product_efficacy-header {
  font-family: 'Campton-Bold';
  font-size: 48px;
  letter-spacing: 0;
  line-height: 52px;
  text-transform: uppercase;
  background: linear-gradient(22.3deg, #78be20 14.62%, #3f961d 84.42%) !important;
  background-clip: text;
  margin: 0px;
}
.FluadPageTemplate span.blacktriangle{
  color:black !important;
}
.FluadPageTemplate div#home_dashleftpadding{
  margin-top:10% !important;
}
.FluadPageTemplate .referencesContainerHomeUK.grbnumber.margin-B20{
  font-size: 14px;
  color: #5D6F7B;
  font-family: 'Campton-Book';
  margin-left:-1%;
}

.joinAccount_text5 a{
  font-family: 'Campton-SemiBold';
  color: inherit;
}
a.footerterms, a.footerterms:hover {
  font-size: 12px !important;
  font-family: Campton-book !important;
  color: #fff !important;
}
.footertermsheader1uk{
  font-family: Campton-semibold;
  color:#fff;
  font-size:16px;
  margin-bottom: 10px;
}
.ExplicitConsentPageTemplate a:focus {
  outline: 2px solid #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  outline-offset: 0;
}
.InvoicePageTemplate .account-section-content .createprofile_helptext {
    padding-right: 7%;
}

.efficacyandSafetyPageTemplate {
  @media only screen and (max-width: 1023px) {
    app-clinical-flucelvax-second {
      width: 100% ;
    }
    app-clinical-flucelvax-first {
      width: 102%;
    }
  }
}

.AccountPageTemplate .BodyContent:has(app-contact-us-topbanner .us-Lo-contact-us),.LandingPage5Template .BodyContent:has(app-contact-us-topbanner .us-Lo-contact-us){
  width: 100% !important;
}
.AccountPageTemplate .BodyContent:has(app-contact-us-topbanner .topbannerUSNonWE),.LandingPage5Template .BodyContent:has(app-contact-us-topbanner .topbannerUSNonWE){
  width: 100% !important;
}
.mat-typography:has(.AccountPageTemplate >.BodyContent app-contact-us-form),.mat-typography:has(.LandingPage5Template >.BodyContent app-contact-us-form){
  letter-spacing: normal;
}
app-contact-us-side:has(.sub-container .Support_flu360header){
  width: 96%;
  margin-left: 12px;
}
app-contact-us-form:has(.Support-customerFeedbackparent){
  width: 76% !important;
  margin-left: 26%;
}
.AccountPageTemplate .BodyContent:has(app-contact-us-form .contact_Support_NWE), .LandingPage5Template .BodyContent:has(app-contact-us-form .contact_Support_NWE){
  grid-template-columns: 55% 45%;
}
app-contact-us-form:has(.contact_Support_NWE){
  margin-left: 10px;
}


  //return
  #forUKReturns .return_headerpara p {
    font-size: 14px;
    line-height: 21px;
    font-family: 'Campton-Book';
    color: #2A3237;
  }
  #forUKReturns .return_headerpara a {
    font-family: 'Campton-Medium';
    text-decoration: underline !important;
  }
  #forUKReturns .return_headerpara a:hover {
    font-family: 'Campton-Medium';
    text-decoration: none !important;
  }
  .return_mat thead{display: none;}
  #forUKReturns #returnfilterTable thead{display: none !important;}
  #forUKReturns #returnfilterTable tbody tr.active, #forUKReturns #returnfilterTable tbody tr:hover {
    border-left: 3px solid #EA181B !important;
    background-color: #F9FAFA;
  }
  .cdk-overlay-pane.mat-mdc-dialog-panel{
    width:780px  ;
    padding:30px 15px;


  }



  .mdc-dialog__container{background: #fff !important;  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);}
  .mat-mdc-dialog-container .mdc-dialog__surface{
      border-radius: 0px !important;
      box-shadow: none !important;
     // overflow: hidden;
  }

  .mat-mdc-dialog-container .mdc-dialog__container {
    transition: opacity linear var(--mat-dialog-transition-duration, 0ms);
    padding: 25px 30px;
    background: #fff;
  }
  .loading {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.3), rgba(0, 0, 0, 0.3));
    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.3), rgba(0, 0, 0, 0.3));
  }

  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  }

  .loading .loading_txt {
    background: #fff;
    padding: 3% 5%;
    /* z-index: 999999999999999; */
    position: fixed;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .loading .loading_txt .load_txt {
    color: #5D6F7B;
    font-family: Campton-medium;
    font-size: 14px;
    text-align: center;
    margin-bottom: 13px;
  }

  @keyframes scaling {

    0%,
    100% {
        transform: scale(1);
        background-color: #DBE0E2;
    }

    40% {
        transform: scale(1);
        background-color: #EA181B;
    }

    50% {
        transform: scale(1);
        background-color: #EA181B;
    }
  }

  .loading span {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin: .7rem;
    animation-name: scaling;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 4s;
  }

  .loading span:nth-child(0) {
    animation-delay: 0s;
  }

  .loading span:nth-child(1) {
    animation-delay: 0.2s;
  }

  .loading span:nth-child(2) {
    animation-delay: 0.4s;
  }

  .loading span:nth-child(3) {
    animation-delay: 0.6s;
  }

  .loading span:nth-child(4) {
    animation-delay: 0.8s;
  }

  .loading span:nth-child(5) {
    animation-delay: 1s;
  }
  .noMyReturnText {
    margin-top: 15px;
    margin-bottom: 40px;
    font-family: 'Campton-Book';
    font-size: 16px;
    line-height: 21px;
    color: #2A3237;
  }

  .g-box{display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    border: 2px dashed #AEB7BD;
    height: auto;
    background: #fff;
  }
    gallery-slider{
      max-width: 65%;
      height: 240px;
      display: flex !important;
      justify-content: center !important;
      width: 100%;
      position: relative;
      margin: 0px auto;
      overflow: initial !important;
    }
    .g-nav-prev{
      left:-16% !important;
    }
    .g-nav-prev:hover{
      left:-16% !important;
    }

    .g-nav-next{
      right:-16% !important;
    }
    .g-nav-next:hover{
      right:-16% !important;
    }
    .g-slider-content{
      background: #fff;
      margin-top: 20px;
    }

    gallery-thumb{
      margin: 0px 10px 10px 0px;
    }
    .g-slider{background-color: #fff;}
    .g-active-thumb{border: 4px solid #2A3237;}

    .ngx-file-drop__drop-zone{ border:none !important; border-radius: 0px !important; text-align: center;}

    .ngx-file-drop__content[_ngcontent-ng-c2301008647] {
      display: block !important;
      height: 100px;
      color: #2A3237 !important;
      font-size: 16px !important;
      line-height: 45px !important;
  }
  .ng-star-inserted .btn-primary {
    display: inline-block !important;
    padding: 10px !important;
    background: #fff !important;
    cursor: pointer !important;
    border: 2px solid #5D6F7B !important;
    border-radius: 2px !important;
    width: 100px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: #2A3237 !important;
    font-size: 14px;
  }
  .ng-star-inserted .btn-primary:hover {
    display: inline-block !important;
    padding: 10px !important;
    background: #5D6F7B !important;
    cursor: pointer !important;
    border: 2px solid #5D6F7B !important;
    border-radius: 2px !important;
    width: 100px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: #fff !important;
    font-size: 14px;
  }

  .cdk-global-overlay-wrapper{overflow: auto;}
  .custom-backdrop {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    opacity: 1 !important;
  }
.consentsubheader a{
  text-decoration: underline !important;
}
.AccountPageTemplate app-credit-top-banner,.AccountPageTemplate app-credits-section{
  grid-column: 1 / span 2;
}
.mat-typography:has(.AccountPageTemplate >.BodyContent app-credits-section){
  letter-spacing: normal !important;
  line-height: normal !important;
}
.forUKReturns{
  .credits_creditheaderTooltip a{
    text-decoration: underline !important;
    font-family: 'Campton-Medium';
    margin-left: 5px;
  }
}
.AccountPageTemplate .BodyContent:has(app-credits-section) app-jobbagcode-uk .grbnumber {
  color: #5d6f7b;
  margin: 0px 10px 20px;
  padding-top: 10px;
  margin-left:-48%;
}
.AccountPageTemplate .BodyContent:has(app-credits-section .credits_thankyou) app-jobbagcode-uk .grbnumber {
  margin-top: 30%;
}
.Explictfooter-link .footerterm a,
.Explictfooter-link .footerterm a:visited,
.Explictfooter-link .footerterm a:active,
.Explictfooter-link .footerterm a:hover {
  font-size: 12px !important;
  font-family: Campton-book !important;
  color: #fff !important;
}

.LandingPage2Template {
  .about-ref {
    strong {
      font-family: "Campton-Bold", sans-serif;
      font-weight: bold !important;
    }
    p{
      font-size: 14px;
      color: #5d6f7b;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}


 app-registration-uk{border-top:1px solid #DBE0E2 !important ; width: 27% !important;}
 .AccountPageTemplate .BodyContent:has(app-custom-account-info){
  grid-template-columns: 43% 57%;
}
 //orders

 .orders.no-matching{
  margin-bottom: 0px;
  line-height: 20px;
 }
 .mat-column-latestStatus{
  padding-left: 10px !important;
  padding-right: 0px !important;
 }
/*  .productdetailTable .view_status:before{
  font-size: 16px;
 } */
 .AccountPageTemplate .BodyContent:has(app-order-summary) app-custom-user-guide{
  margin-left: -15px;
}
#open_order_search:focus{
  outline: 2px solid #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  outline-offset: 0;
}
.savedCardFailedStatusPageTemplate header,.PaymentStatusPageTemplate header{
  border-bottom:none;

  .header{
    border-bottom: none !important;
  }
}

.loggedin .RegistrationAccountTemplate .registration_leftside{display: none !important;}
.loggedin .RegistrationAccountTemplate .registration_rightside {padding: 0px 12% 0px 60px !important;max-width: 94% !important;}
.RegistrationAccountTemplate .newresgistrationtitle{display: none !important;}
.RegistrationAccountTemplate .contactsubsection{display: none !important;}
.RegistrationAccountTemplate .log_Reg_Navigatesection{display: none !important;}
.RegistrationAccountTemplate app-custom-left-navigation{display: none;}
cx-page-layout.RegistrationAccountTemplate .BodyContentUK{width:100% !important;}
.loggedin cx-page-layout.RegistrationAccountTemplate .BodyContentUK {
  width: 83.333% !important;
}
.loggedin .RegistrationAccountTemplate app-custom-left-navigation{display: block !important;}
.loggedin .RegistrationAccountTemplate .newresgistrationtitle{display: block !important;}
.loggedin .RegistrationAccountTemplate .contactsubsection{display: block !important;}
.loggedin .RegistrationAccountTemplate .log_Reg_Navigatesection{display: flex !important;}
.loggedin .RegistrationAccountTemplate .nonweb{display: none !important;}
.RegistrationAccountTemplate .nonweb{display: block !important;}
app-having-trouble .createprofile_helptext{
    width: fit-content;
    padding-right: 0px !important;
    margin-bottom: 0px;
    float: right;
}

.StaticMediaPageTemplate {
 .SiteLogin {
    cx-login {
      a {
        display: none;
      }
    }
  }
}
.LandingPage2Template {
.headerbar .nav-item .indicator_arrow i{
  font-size: 26px;
  font-weight: 400;
}
}
.footer_parawidth{
  .footerpara-2{
    padding-right: 10%;
    letter-spacing: 0;
  }
}

.seqirusb2b-uk #login-container .gigya-screen-content div.gigya-screen.landscape {
  padding-top: 50px !important;
}
.seqirusb2b-uk  .is-initialized img{
      max-height: 695px;
}
.CreateOrderPageTemplate app-jobbagcode-uk .grbnumber {
  color: #5d6f7b;
  margin: 0px 10px 20px;
  padding-top: 10px;
  margin-left: -23%;
}
.all-invoice-us .cdk-overlay-container {
  background-color: transparent;
}
.body:has(.PaymentStatusPageTemplate){
  overflow-y: hidden;
}
.AccountPageTemplate .BodyContent:has(app-join-acc-thankyou) app-jobbagcode-uk .grbnumber {
  color: #5D6F7B;
}
.RegistrationAccountTemplate .BodyContentUK:has(app-registration-uk) app-jobbagcode-uk .grbnumber {
  margin-left: 29%;
  color: #5D6F7B;
}
.seqirusb2b-uk .AccountPageTemplate .BodyContent:has(app-summarybanner) {
  grid-template-columns: 50% 50%;
}
body.seqirusb2b-uk app-terms-headline .col-md-12.margin-control{
  top: 0;
  margin-top: 6%;
  margin-bottom: 0;
}
#prodLocation1  .form-control[type=text]:focus{
  background-color: #ffffff !important;
}
#prodLocation1  .form-control[type=text]:disabled{
 color: #8E9AA3 !important;
 background-color: #eeeeee !important;
}
body.loggedin .TopHeaderSlot{
  width: 100%;
}
.seqirusb2b-uk .AccountPageTemplate .BodyContent:has(app-uk-my-orders-left-section){
  app-jobbagcode-uk{
    grid-column: 1 / span 2;
    position: relative;
    margin-left: -23%;
    .referencesContainerHomeUK{
      font-family: "Campton-Book";
    padding: 0% 52px 0% 52px;
    }
  }
}

.seqirusb2b-uk.loggedin{
.RegistrationAccountTemplate .BodyContentUK:has(app-registration-uk) app-jobbagcode-uk .grbnumber{
 margin-left: 0 !important;
}
}

.seqirusb2b-uk.loggedin{
  .RegistrationAccountTemplate .BodyContent:has(app-registration-uk-thankyou){
    .registration_thankyou_rightside{
      display: none;
    }
  }
}
//scheduled payments background colour for even rows in mobile view
body:has(app-scheduled-payments-section) .AccountPageTemplate {
  @media (max-width: 991px) {
    #schedulepay_mobile_table .table-row:nth-child(4n+3) {
      background-color: #F9FAFA !important;
    }
  }
}
.ReturnsPageTemplate {
  :not(:has(app-return-banner)) .BodyContent:not(:has(app-us-returns-form .return_form_topheader_loggedin)){
    width: 92%;
  }
  app-us-returns-form{
    width: 83.33%;
  }
  .returns_popupredtext{
    color: #EA181B;
    font-size: 14px;
    font-family: Campton-semibold;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
  }
  .returns_popupsubtext{
    color: #5D6F7B;
    font-family: Campton-book; 
    font-weight: 400;
  }
}
.seqirusb2b-uk.loggedin .ResourcesLandingTemplate .grbnumber{
  float:left;
}
